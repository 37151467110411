import styled from 'styled-components';

type IObject = {
  [key: string]: IObject | string | number,
};

const deepModify = <S extends IObject>(source: S, modification: IObject): S => {
  const result = {} as any;
  Object.keys(source).forEach((key: string) => {
    const s = source[key];
    const m = modification && modification[key];
    if (typeof m === 'string' || typeof m === 'number') {
      result[key] = m;
    } else if (s instanceof Object
      && typeof s !== 'string' && typeof s !== 'number') {
      result[key] = deepModify(s, m);
    } else {
      result[key] = s;
    }
  });
  return result;
};

export type IDressingRoomTheme = {
  general: IObject,
  button: IObject,
  buyButton: IObject,
  disabledButton: IObject,
  toggledButton: IObject,
  zoomButton: IObject,
  title: IObject,
  subTitle: IObject,
  paragraph: IObject,
  cart: IObject,
  svgFill: IObject,
  svgOutline: IObject,
  unavailable: IObject,
  [key: string]: IObject | string | number,
};

const blackish = 'rgb(60, 64, 76)';

export const FisionTheme: IDressingRoomTheme = {
  general: {
    fontFamily: '"Nimbus", sans-serif',
    color: blackish,
    userSelect: 'none',
  },
  button: {
    backgroundColor: 'transparent',
    border: `2px solid ${blackish}`,
    opacity: 0.8,
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
    padding: '8px',
    cursor: 'pointer',
    letterSpacing: '1.4px',
    wordSpacing: '1.4px',
    textTransform: 'uppercase',
    boxSizing: 'border-box',
    display: 'inline-block',
    borderRadius: '4px',
  },
  buyButton: {
    border: `2px solid ${blackish}`,
    backgroundColor: 'transparent',
    color: blackish,
    opacity: 0.8,
  },
  disabledButton: {
    border: `2px solid ${blackish}`,
    backgroundColor: 'transparent',
    color: blackish,
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  toggledButton: {
    border: '2px solid rgb(255, 255, 255)',
    backgroundColor: 'rgb(255, 255, 255)',
    opacity: 1.0,
  },
  zoomButton: {
    backgroundColor: 'transparent',
    border: `2px solid ${blackish}`,
    opacity: 0.8,
    borderRadius: '4px',
    width: '16px',
    height: '16px',
    padding: '6px',
    marginTop: '4px',
    marginLeft: '4px',
    cursor: 'pointer',
  },
  title: {
    fontFamily: '"Replica", sans-serif',
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: 1.2,
    letterSpacing: 'normal',
    wordSpacing: 'normal',
    textTransform: 'uppercase',
  },
  subTitle: {
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  paragraph: {
    fontSize: '14px',
    lineHeight: 1.5,
  },
  cart: {
    background: 'linear-gradient(to top, rgba(255, 255, 255, 0.3) 40%, rgba(255, 255, 255, 0.0))',
  },
  svgFill: {
    display: 'block',
    fill: blackish,
  },
  svgOutline: {
    display: 'block',
    stroke: blackish,
  },
  unavailable: {
    color: 'rgb(200, 200, 200)',
  },
};

const alphaTauriColor = 'rgb(29, 29, 27)';

const alphatauriModification = {
  general: {
    fontFamily: '"Neue Haas Unica W01 Regular", Helvetica, Arial, sans-serif',
    color: alphaTauriColor,
  },
  title: {
    fontFamily: '"Neue Haas Unica W01 Regular", Helvetica, Arial, sans-serif',
    fontSize: '24px',
    letterSpacing: '0.1em',
    wordSpacing: '0.1em',
  },
  button: {
    border: `2px solid ${alphaTauriColor}`,
  },
  buyButton: {
    border: `2px solid ${alphaTauriColor}`,
    backgroundColor: alphaTauriColor,
    color: 'rgb(255, 255, 255)',
    opacity: 1.0,
  },
  disabledButton: {
    border: `2px solid ${alphaTauriColor}`,
    color: alphaTauriColor,
  },
  zoomButton: {
    border: `2px solid ${alphaTauriColor}`,
  },
  svgFill: {
    fill: alphaTauriColor,
  },
  svgOutline: {
    stroke: alphaTauriColor,
  },
};

export const AlphatauriTheme = deepModify(FisionTheme, alphatauriModification);

export const GeneralStyle = styled.div(props => props.theme.general);

export const Title = styled.div(props => props.theme.title);
export const SubTitle = styled.div(props => props.theme.subTitle);
export const Paragraph = styled.div(props => props.theme.paragraph);

export enum ButtonType {
  Default,
  Disabled,
  Buy,
}

export const Button = styled.div<{ type?: ButtonType, toggled?: boolean }>`
  ${props => props.theme.button}
  ${props => (props.toggled ? props.theme.toggledButton : {})}

  ${props => {
    switch (props.type) {
      case ButtonType.Disabled: return props.theme.disabledButton;
      case ButtonType.Buy: return props.theme.buyButton;
      default: return '';
    }
  }}
`;

export const ZoomButton = styled.div(props => props.theme.zoomButton);

export const CartBackground = styled.div(props => props.theme.cart);

export const FilledSVG = styled.svg(props => props.theme.svgFill);
export const OutlinedSVG = styled.svg(props => props.theme.svgOutline);

export const Unavailable = styled.span(props => props.theme.unavailable);
