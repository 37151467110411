export type BodyMeasurements = {
  height: number,
  chest: number,
  waist: number,
};

const normalizedMeasurements = (measurements: BodyMeasurements) => [
  measurements.chest / measurements.height,
  measurements.waist / measurements.height,
];

const sqDistance = (array1: number[], array2: number[]) => {
  let total = 0;
  array1.forEach((value1, index) => {
    const diff = value1 - array2[index];
    total += diff * diff;
  });
  return total;
};

const sum = (array: number[]) => array.reduce((total, value) => total + value, 0);

const max = (array: number[]) => array.reduce((currentMax, value) => Math.max(value, currentMax), -Number.MAX_VALUE);

const normalizeVector = (array: number[]) => {
  const total = sum(array);
  return array.map(value => value / total);
};

const prune = (array: number[], threshold: number) => {
  const pruned = array.map(value => (value > threshold ? value : 0));
  if (sum(pruned) === 0) {
    const maxValue = max(array);
    return array.map(value => (value === maxValue ? 1 : 0));
  }
  return pruned;
};

export const weightedNearestNeighbor = (
  bodies: BodyMeasurements[],
  target: BodyMeasurements,
): number[] => {
  const targetNorm = normalizedMeasurements(target);
  const weights = bodies.map(body => {
    const distance = sqDistance(targetNorm, normalizedMeasurements(body));
    return distance === 0 ? Number.MAX_VALUE : 1 / distance;
  });
  return normalizeVector(prune(normalizeVector(weights), 0.05));
};

export const weightedNearestNeighborWinnerTakesAll = (
  bodies: BodyMeasurements[],
  target: BodyMeasurements,
): number[] => {
  const targetNorm = normalizedMeasurements(target);
  let nearestIndex = -1;
  let minDistance = Number.MAX_VALUE;
  bodies.forEach((body, index) => {
    const distance = sqDistance(targetNorm, normalizedMeasurements(body));
    if (distance < minDistance) {
      minDistance = distance;
      nearestIndex = index;
    }
  });

  return bodies.map((body, index) => (index === nearestIndex ? 1 : 0));
};
