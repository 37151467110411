import * as THREE from 'three';

export const getVertices = (object3D: THREE.Object3D) => {
  if (object3D.children.length < 1) {
    return [];
  }
  const mesh = object3D.children[0] as THREE.Mesh;
  const geometry = mesh.geometry as THREE.BufferGeometry;
  const position = geometry.attributes.position as THREE.BufferAttribute;

  const positions: THREE.Vector3[] = [];
  for (let index = 0; index < position.count; index += 1) {
    const vector = new THREE.Vector3();
    vector.fromBufferAttribute(position, index);
    positions.push(vector);
  }
  return positions;
};

export const interpolateMeshVertexPositions = (
  sources: Array<{
    positions: THREE.Vector3[],
    weight: number,
  }>,
  target: THREE.Mesh,
) => {
  const targetPositions: THREE.BufferAttribute[] = [];
  const mesh = target.children[0] as THREE.Mesh;
  if (mesh == null) {
    return;
  }
  const geometry = mesh.geometry as THREE.BufferGeometry;
  targetPositions.push(geometry.attributes.position as THREE.BufferAttribute);

  const weightSum = sources.reduce((total, source) => total + source.weight, 0);
  const n = sources[0].positions.length;
  for (let index = 0; index < n; index += 1) {
    const targetPosition = new THREE.Vector3();
    sources.forEach(source => {
      targetPosition.addScaledVector(source.positions[index], source.weight / weightSum);
    });

    targetPositions.forEach(positionAttribute => {
      positionAttribute.setX(index, targetPosition.x);
      positionAttribute.setY(index, targetPosition.y);
      positionAttribute.setZ(index, targetPosition.z);
    });
  }
  targetPositions.forEach(positionAttribute => {
    // eslint-disable-next-line no-param-reassign
    positionAttribute.needsUpdate = true;
  });
  geometry.computeVertexNormals();
};
