/* eslint-disable max-len, no-nested-ternary */
export const getShopLink = (
  urlPrefix: string,
  id: string,
) => `${urlPrefix}${id}`;

export const getSize = (
  garmentId: string,
  halfChest: number,
  sleeveLength: number,
  backLength: number,
): string | null => {
  const sizeFunc = alphatauriSizes[garmentId];
  if (!sizeFunc) {
    console.error('unknown garment id', garmentId);
    return null;
  }
  const size = sizeFunc(halfChest, sleeveLength, backLength);
  return size.length === 3 ? size : null;
};

export const sizeToVariantId = (
  firstId: string,
  size: string,
) => {
  if (!firstId.startsWith('M-') || size.length !== 3) {
    return null;
  }
  const mCode = parseInt(firstId.substring(2), 10);
  const size1 = parseInt(size.substring(0, 1), 10);
  const size2 = parseInt(size.substring(1), 10);
  return `M-${mCode + 10 * (size1) + (size2 - 1)}`;
};

const womenSize2 = (F36: number) => (
  F36 > 54.5
    ? '10'
    : (
      F36 > 50.9
        ? '09'
        : (
          F36 > 48.2
            ? '08'
            : (
              F36 > 46.3
                ? '07'
                : (
                  F36 > 44.5
                    ? '06'
                    : (
                      F36 > 42.8
                        ? '05'
                        : (
                          F36 > 41.2
                            ? '04'
                            : (
                              F36 > 39.7
                                ? '03'
                                : (
                                  F36 > 38.2
                                    ? '02'
                                    : (
                                      F36 <= 38.2
                                        ? '01'
                                        : ''
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);

const manSize2 = (F36: number) => (
  F36 > 63.4
    ? '10'
    : (
      F36 > 60.4
        ? '09'
        : (
          F36 > 57.6
            ? '08'
            : (
              F36 > 54.8
                ? '07'
                : (
                  F36 > 52.3
                    ? '06'
                    : (
                      F36 > 50.1
                        ? '05'
                        : (
                          F36 > 47.8
                            ? '04'
                            : (
                              F36 > 45.6
                                ? '03'
                                : (
                                  F36 > 43.3
                                    ? '02'
                                    : (
                                      F36 < 43.3
                                        ? '01'
                                        : ''
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    )
);

export const alphatauriSizes: {
  [garmenId: string]: (halfChest: number, sleeveLength: number, backLength: number) => string,
} = {
  ATA20272: (halfChest: number, sleeveLength: number) => {
    const size1 = (F36: number, F45: number) => (
      (F36 > 54.5 && F45 > 84.6)
        ? '2'
        : (
          (F36 > 54.5 && F45 > 82.2)
            ? '1'
            : (
              (F36 > 54.5 && F45 < 82.2)
                ? '0'
                : (
                  (F36 > 50.9 && F45 > 83.8)
                    ? '2'
                    : (
                      (F36 > 50.9 && F45 > 81.4)
                        ? '1'
                        : (
                          (F36 > 50.9 && F45 < 81.4)
                            ? '0'
                            : (
                              (F36 > 48.2 && F45 > 83)
                                ? '2'
                                : (
                                  (F36 > 48.2 && F45 > 80.6)
                                    ? '1'
                                    : (
                                      (F36 > 48.2 && F45 < 80.6)
                                        ? '0'
                                        : (
                                          (F36 > 46.3 && F45 > 82.1)
                                            ? '2'
                                            : (
                                              (F36 > 46.3 && F45 > 79.7)
                                                ? '1'
                                                : (
                                                  (F36 > 46.3 && F45 < 79.7)
                                                    ? '0'
                                                    : (
                                                      (F36 > 44.5 && F45 > 81.3)
                                                        ? '2'
                                                        : (
                                                          (F36 > 44.5 && F45 > 78.9)
                                                            ? '1'
                                                            : (
                                                              (F36 > 44.5 && F45 < 78.9)
                                                                ? '0'
                                                                : (
                                                                  (F36 > 42.8 && F45 > 80.5)
                                                                    ? '2'
                                                                    : (
                                                                      (F36 > 42.8 && F45 > 78.1)
                                                                        ? '1'
                                                                        : (
                                                                          (F36 > 42.8 && F45 < 78.1)
                                                                            ? '0'
                                                                            : (
                                                                              (F36 > 41.2 && F45 > 79.7)
                                                                                ? '2'
                                                                                : (
                                                                                  (F36 > 41.2 && F45 > 77.3)
                                                                                    ? '1'
                                                                                    : (
                                                                                      (F36 > 41.2 && F45 < 77.3)
                                                                                        ? '0'
                                                                                        : (
                                                                                          (F36 > 39.7 && F45 > 78.8)
                                                                                            ? '2'
                                                                                            : (
                                                                                              (F36 > 39.7 && F45 > 76.4)
                                                                                                ? '1'
                                                                                                : (
                                                                                                  (F36 > 39.7 && F45 < 76.4)
                                                                                                    ? '0'
                                                                                                    : (
                                                                                                      (F36 > 38.2 && F45 > 78)
                                                                                                        ? '2'
                                                                                                        : (
                                                                                                          (F36 > 38.2 && F45 > 75.6)
                                                                                                            ? '1'
                                                                                                            : (
                                                                                                              (F36 > 38.2 && F45 < 75.6)
                                                                                                                ? '0'
                                                                                                                : (
                                                                                                                  (F36 < 38.2 && F45 > 77.2)
                                                                                                                    ? '2'
                                                                                                                    : (
                                                                                                                      (F36 < 38.2 && F45 > 74.8)
                                                                                                                        ? '1'
                                                                                                                        : (
                                                                                                                          (F36 < 38.2 && F45 < 74.8)
                                                                                                                            ? '0'
                                                                                                                            : ''
                                                                                                                        )
                                                                                                                    )
                                                                                                                )
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
    return `${size1(halfChest, sleeveLength)}${womenSize2(halfChest)}`;
  },
  ATA20273: (halfChest: number, sleeveLength: number, backLength: number) => {
    const size1 = (F48: number) => (F48 < 40
      ? ''
      : (
        F48 < 59.675
          ? '0'
          : (
            F48 < 62.675
              ? '1'
              : (
                F48 > 75
                  ? ''
                  : '2'
              )
          )
      )
    );
    return `${size1(backLength)}${womenSize2(halfChest)}`;
  },
  ATA20270: (halfChest: number, sleeveLength: number) => {
    const size1 = (F36: number, F45: number) => (
      (F36 > 63.4 && F45 > 92.4)
        ? '2'
        : (
          (F36 > 63.4 && F45 > 89.4)
            ? '1'
            : (
              (F36 > 63.4 && F45 < 89.4)
                ? '0'
                : (
                  (F36 > 60.4 && F45 > 91.5)
                    ? '2'
                    : (
                      (F36 > 60.4 && F45 > 88.5)
                        ? '1'
                        : (
                          (F36 > 60.4 && F45 < 88.5)
                            ? '0'
                            : (
                              (F36 > 57.6 && F45 > 90.6)
                                ? '2'
                                : (
                                  (F36 > 57.6 && F45 > 87.6)
                                    ? '1'
                                    : (
                                      (F36 > 57.6 && F45 < 87.6)
                                        ? '0'
                                        : (
                                          (F36 > 54.8 && F45 > 89.7)
                                            ? '2'
                                            : (
                                              (F36 > 54.8 && F45 > 86.7)
                                                ? '1'
                                                : (
                                                  (F36 > 54.8 && F45 < 86.7)
                                                    ? '0'
                                                    : (
                                                      (F36 > 52.3 && F45 > 88.8)
                                                        ? '2'
                                                        : (
                                                          (F36 > 52.3 && F45 > 85.8)
                                                            ? '1'
                                                            : (
                                                              (F36 > 52.3 && F45 < 85.8)
                                                                ? '0'
                                                                : (
                                                                  (F36 > 50.1 && F45 > 87.9)
                                                                    ? '2'
                                                                    : (
                                                                      (F36 > 50.1 && F45 > 84.9)
                                                                        ? '1'
                                                                        : (
                                                                          (F36 > 50.1 && F45 < 84.9)
                                                                            ? '0'
                                                                            : (
                                                                              (F36 > 47.8 && F45 > 87)
                                                                                ? '2'
                                                                                : (
                                                                                  (F36 > 47.8 && F45 > 84)
                                                                                    ? '1'
                                                                                    : (
                                                                                      (F36 > 47.8 && F45 < 84)
                                                                                        ? '0'
                                                                                        : (
                                                                                          (F36 > 45.6 && F45 > 86.1)
                                                                                            ? '2'
                                                                                            : (
                                                                                              (F36 > 45.6 && F45 > 83.1)
                                                                                                ? '1'
                                                                                                : (
                                                                                                  (F36 > 45.6 && F45 < 83.1)
                                                                                                    ? '0'
                                                                                                    : (
                                                                                                      (F36 > 43.3 && F45 > 85.1)
                                                                                                        ? '2'
                                                                                                        : (
                                                                                                          (F36 > 43.3 && F45 > 82.1)
                                                                                                            ? '1'
                                                                                                            : (
                                                                                                              (F36 > 43.3 && F45 < 82.1)
                                                                                                                ? '0'
                                                                                                                : (
                                                                                                                  (F36 < 43.3 && F45 > 84.1)
                                                                                                                    ? '2'
                                                                                                                    : (
                                                                                                                      (F36 < 43.3 && F45 > 81.1)
                                                                                                                        ? '1'
                                                                                                                        : (
                                                                                                                          (F36 < 43.3 && F45 < 81.1)
                                                                                                                            ? '0'
                                                                                                                            : ''
                                                                                                                        )
                                                                                                                    )
                                                                                                                )
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
    return `${size1(halfChest, sleeveLength)}${manSize2(halfChest)}`;
  },
  ATA20271: (halfChest: number, sleeveLength: number, backLength: number) => {
    const size1 = (F36: number, F46: number) => (
      (F36 > 63.4 && F46 > 71.6)
        ? '2'
        : (
          (F36 > 63.4 && F46 > 69.6)
            ? '1'
            : (
              (F36 > 63.4 && F46 < 69.6)
                ? '0'
                : (
                  (F36 > 60.4 && F46 > 70.7)
                    ? '2'
                    : (
                      (F36 > 60.4 && F46 > 68.7)
                        ? '1'
                        : (
                          (F36 > 60.4 && F46 < 68.7)
                            ? '0'
                            : (
                              (F36 > 57.6 && F46 > 69.8)
                                ? '2'
                                : (
                                  (F36 > 57.6 && F46 > 67.8)
                                    ? '1'
                                    : (
                                      (F36 > 57.6 && F46 < 67.8)
                                        ? '0'
                                        : (
                                          (F36 > 54.8 && F46 > 69)
                                            ? '2'
                                            : (
                                              (F36 > 54.8 && F46 > 67)
                                                ? '1'
                                                : (
                                                  (F36 > 54.8 && F46 < 67)
                                                    ? '0'
                                                    : (
                                                      (F36 > 52.3 && F46 > 68.2)
                                                        ? '2'
                                                        : (
                                                          (F36 > 52.3 && F46 > 66.2)
                                                            ? '1'
                                                            : (
                                                              (F36 > 52.3 && F46 < 66.2)
                                                                ? '0'
                                                                : (
                                                                  (F36 > 50.1 && F46 > 67.6)
                                                                    ? '2'
                                                                    : (
                                                                      (F36 > 50.1 && F46 > 65.6)
                                                                        ? '1'
                                                                        : (
                                                                          (F36 > 50.1 && F46 < 65.6)
                                                                            ? '0'
                                                                            : (
                                                                              (F36 > 47.8 && F46 > 67)
                                                                                ? '2'
                                                                                : (
                                                                                  (F36 > 47.8 && F46 > 65)
                                                                                    ? '1'
                                                                                    : (
                                                                                      (F36 > 47.8 && F46 < 65)
                                                                                        ? '0'
                                                                                        : (
                                                                                          (F36 > 45.6 && F46 > 66.3)
                                                                                            ? '2'
                                                                                            : (
                                                                                              (F36 > 45.6 && F46 > 64.3)
                                                                                                ? '1'
                                                                                                : (
                                                                                                  (F36 > 45.6 && F46 < 64.3)
                                                                                                    ? '0'
                                                                                                    : (
                                                                                                      (F36 > 43.3 && F46 > 65.7)
                                                                                                        ? '2'
                                                                                                        : (
                                                                                                          (F36 > 43.3 && F46 > 63.7)
                                                                                                            ? '1'
                                                                                                            : (
                                                                                                              (F36 > 43.3 && F46 < 63.7)
                                                                                                                ? '0'
                                                                                                                : (
                                                                                                                  (F36 < 43.3 && F46 > 65)
                                                                                                                    ? '2'
                                                                                                                    : (
                                                                                                                      (F36 < 43.3 && F46 > 63)
                                                                                                                        ? '1'
                                                                                                                        : (
                                                                                                                          (F36 < 43.3 && F46 < 63)
                                                                                                                            ? '0'
                                                                                                                            : ''
                                                                                                                        )
                                                                                                                    )
                                                                                                                )
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
    return `${size1(halfChest, backLength)}${manSize2(halfChest)}`;
  },
};
