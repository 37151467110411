import * as React from 'react';
import styled from 'styled-components';

const CloseButton = (props: {
  onClick: () => void,
}) => (
  <s.close
    onClick={props.onClick}
    width={48}
    height={48}
  >
    <g transform="translate(24, 24)">
      <s.circle r={24} />
      <s.line x1={-10} y1={10} x2={10} y2={-10} />
      <s.line x1={-10} y1={-10} x2={10} y2={10} />
    </g>
  </s.close>
);

export default CloseButton;

const s = {
  close: styled.svg`
    cursor: pointer;
  `,
  circle: styled.circle`
    fill: rgb(0, 0, 0);
    opacity: 0.8;
    stroke: none;
  `,
  line: styled.line`
    stroke: rgb(255, 255, 255);
    stroke-width: 5;
  `,
};
