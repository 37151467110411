import QR from 'qrcode.react';
import * as React from 'react';
import styled from 'styled-components';

import { ButtonType, SubTitle, Title } from '../../dressing-room/lib/dressingRoom/DressingRoomTheme';
import ActionButton from '../../dressing-room/lib/dressingRoom/product/ActionButton';

import { Garment } from './garments_on_bodies';
import { sendQRCode } from './print_qrcode';

const ProductInfo = (props: {
  product: Garment | null,
  recommendedVariantLink: string | null,
  qrCodePrintUrl: string,
  onPrint: () => void,
}) => {
  const {
    product,
    recommendedVariantLink,
    qrCodePrintUrl,
    onPrint,
  } = props;

  if (!product) {
    return null;
  }

  return (
    <s.productInfoContainer>
      <s.productSubtitle>{product.id}</s.productSubtitle>
      <s.productTitle>{product.headline}</s.productTitle>
      {product.summary && (<s.summary>{product.summary}</s.summary>)}
      {product.price && (
        <s.price>
          {product.originalPrice && <s.originalPrice>{product.originalPrice}</s.originalPrice>}
          {product.price}
        </s.price>
      )}
      {recommendedVariantLink && (
      <s.qrCode>
        <QR
          level="H"
          renderAs="svg"
          size={140}
          fgColor="rgb(29, 29, 27)"
          value={recommendedVariantLink}
        />
        <s.checkOutButton
          type={ButtonType.Buy}
          onClick={async () => {
            try {
              await sendQRCode(recommendedVariantLink, qrCodePrintUrl);
            } catch (error) {
              console.error(error);
            }
            onPrint();
          }}
        >
          Print QR code and go to check out
        </s.checkOutButton>
      </s.qrCode>
      )}
    </s.productInfoContainer>
  );
};

export default ProductInfo;

const s = {
  productInfoContainer: styled.div`
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,

  productSubtitle: styled(SubTitle)`
    margin-bottom: 8px;
  `,

  productTitle: styled(Title)`
    margin-bottom: 8px;
  `,

  summary: styled.div`
    margin-top: 12px;
    font-size: 14px;
  `,

  price: styled.div`
    margin-top: 8px;
    font-size: 18px;
    font-weight: 700;
  `,

  originalPrice: styled.span`
    text-decoration-line: line-through;
    opacity: 0.5;
    margin-right: 8px;
  `,

  qrCode: styled.div`
    margin-top: 32px;
  `,

  qrLabel: styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
  `,

  checkOutButton: styled(ActionButton)`
    margin-top: 16px;
  `,
};
