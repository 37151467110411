export type UserIntentState = null | SizeRecommendationIntent | BodyFeaturesIntent | VirtualDressingRoomIntent;

type SizeRecommendationIntent = {
  type: 'SizeRecommendation',
  requestedVariantIdentifier: string,
  productInfo: IProductInfo,
};

type BodyFeaturesIntent = {
  type: 'BodyFeatures',
};

type VirtualDressingRoomIntent = {
  type: 'VirtualDressingRoom',
};

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
}

export enum BodyShape {
  Hourglass = 'Hourglass',
  Round = 'Round',
  Triangle = 'Triangle',
  InvertedTriangle = 'InvertedTriangle',
  Rectangle = 'Rectangle',
}

export type Language = 'en' | 'de';

export type IPlatformConfig = {
  apiKey: string,
  enableQuickSize?: boolean,
  enableOperatorMode?: boolean,
  callbackURL?: string,
  callbackLabel?: string,
  language?: Language,
  disableBodyScan?: boolean,
};

export type IFace = {
  x: number,
  y: number,
  width: number,
  height: number,
};

export type IBodyImage = {
  dataUrl: string,
  face: IFace,
};

export enum IntendedGender {
  Male = 'Male',
  Female = 'Female',
  Unisex = 'Unisex',
}

export type IProductVariantGroupTags = {
  garment_position: string,
  garment_gender: IntendedGender,
};

export type IProductVariantGroup = {
  id: string,
  identifier: string,
  thumbnail_path: string,
  order: number,
  tags: IProductVariantGroupTags,
};

export type IGarmentVariant = {
  product_variant_id: string,
  product_id: string,
  product_variant_group_id: string,
};

export type IGarment = {
  id: string,
  gender: IntendedGender,
};

export type ISimulatedGarment = {
  meshUrl: string,
  outsideTextureUrl: string,
  insideTextureUrl: string,
};

export type ISimulationProcess = {
  id: string,
  body: {
    meshUrl: string,
  },
  upperBodyGarment: ISimulatedGarment,
  lowerBodyGarment: ISimulatedGarment,
};

export type ITestResult = {
  name: string,
  summary: string,
  parameters: any,
  success: boolean,
  result: any,
};

export type IOutputVariant = {
  fitProbability: number,
  identifier: string,
  sizeLabel?: string,
};

export type IRecommendationResult = {
  id: string,
  recommendations: IRecommendation[],
};

export type IVariantFitProbability = {
  fitProbability: number,
  variantIdentifier: string,
  sizeLabel?: string,
};

export type IProductFitProbabilities = {
  productIdentifier: string,
  colorLabel: string,
  variantFitProbabilities: IVariantFitProbability[],
};

export type IRecommendation = {
  inputVariantIdentifier: string,
  outputVariants: IOutputVariant[],
};

export type IRecommendationFeedback = {
  inputVariantIdentifier: string,
  inputIdentifierType: string,
  selectedVariantIdentifier: string,
};

export type IProductInfo = {
  name: string,
};

export type IVariantGroup = {
  identifiers: string[],
  intendedGender: IntendedGender,
  thumbnailPath: string,
  groupId: string,
  productIdentifier: string,
};

export type IPlatform = {
  initialize: (options: IPlatformConfig) => Promise<void>,
  meeplButtonClicked: (userIntent: UserIntentState) => Promise<void>,
  getBodySnapshot: (mannequinUserId?: string) => Promise<IBodyFeaturesSnapshot | null>,
  getBodySnapshotMannequin: (mannequinUserId?: string) => Promise<IBodyFeaturesSnapshot | null>,
  recommendedSizes: (bodySnapshotId: string, variantId: string) => Promise<IRecommendationResult>,
  recommendedSizesMannequin: (bodySnapshotId: string, variantId: string) => Promise<IRecommendationResult>,
  requestSizeRecommendation: (variantIds: string[]) => Promise<void>,
  userRecommendedSizesBatch: (variantIds: string[]) => Promise<IRecommendationResult>,
  getFitProbabilitiesForAllProducts: () => Promise<IProductFitProbabilities[]>,
  recommendationFeedback: (
    recommendationId: string,
    feedbacks: IRecommendationFeedback[]) => Promise<IRecommendationFeedback[]>,
  signIn: (email: string, password: string) => Promise<void>,
  signInAnonymously: () => Promise<void>,
  signUp: (email: string, password: string) => Promise<void>,
  acceptLegalDocuments: () => Promise<void>,
  signOut: () => Promise<void>,
  deleteAccount: (password: string) => Promise<void>,

  getMannequinUserId: (gender: Gender, shape: BodyShape, bodyHeight: number) => Promise<string | null>,

  productVariantGroups: () => Promise<IProductVariantGroup[]>,
  variantGroups: (variantIds: string[]) => Promise<IVariantGroup[]>,

  garmentSimulation: (
    bodyFeaturesSnapshotId: string,
    upperBodyGarmentId: string,
    lowerBodyGarmentId: string,
    userId?: string | null,
  ) => Promise<ISimulationProcess>,

  garmentSimulationMannequin: (
    bodyFeaturesSnapshotId: string,
    upperBodyGarmentId: string,
    lowerBodyGarmentId: string,
    userId?: string | null,
  ) => Promise<ISimulationProcess>,

  getTenantId: () => Promise<string>,
};

export type MeasurementValueType = number | null;
export type MeasurementPathType = [[number]] | null;

export type IBodyMeasurements = {
  bodyHeight: MeasurementValueType,
  innerLegLength: MeasurementValueType,
  waistCircumference: MeasurementValueType,
  waistbeltCircumference: MeasurementValueType,
  armLength: MeasurementValueType,
  chestCircumference: MeasurementValueType,
  hipCircumference: MeasurementValueType,
  neckToGround: MeasurementValueType,
  centerBackLength: MeasurementValueType,
  beltlineCircumference: MeasurementValueType,
  innerLegLengthRight: MeasurementValueType,
  innerLegLengthLeft: MeasurementValueType,
  napeToBeltlineBackLength: MeasurementValueType,
  calfCircumferenceRight: MeasurementValueType,
  calfCircumferenceLeft: MeasurementValueType,
  neckCircumference: MeasurementValueType,
  acrossBackWidth: MeasurementValueType,
  neckHeight: MeasurementValueType,
  napeToWristRight: MeasurementValueType,
  napeToWristLeft: MeasurementValueType,
  armLengthRight: MeasurementValueType,
  armLengthLeft: MeasurementValueType,
  thighCircumferenceRight: MeasurementValueType,
  thighCircumferenceLeft: MeasurementValueType,
  bicepCircumferenceRight: MeasurementValueType,
  bicepCircumferenceLeft: MeasurementValueType,
  napeToScyeDistance: MeasurementValueType,
  napeToWaistBackDistance: MeasurementValueType,
  napeToBustLength: MeasurementValueType,
  napeToWaistOverBustLength: MeasurementValueType,
  waistToHipDistanceRight: MeasurementValueType,
  waistToHipDistanceLeft: MeasurementValueType,
  waistToFloorLengthRight: MeasurementValueType,
  waistToFloorLengthLeft: MeasurementValueType,
  acrossBustLength: MeasurementValueType,
  scyeWidthRight: MeasurementValueType,
  scyeWidthLeft: MeasurementValueType,
  acromionWidth: MeasurementValueType,
  cupDepthRight: MeasurementValueType,
  cupDepthLeft: MeasurementValueType,
  waistToKneeLengthRight: MeasurementValueType,
  waistToKneeLengthLeft: MeasurementValueType,
  acromionToElbowLengthRight: MeasurementValueType,
  acromionToElbowLengthLeft: MeasurementValueType,
  highHipCircumference: MeasurementValueType,
  wristCircumferenceRight: MeasurementValueType,
  wristCircumferenceLeft: MeasurementValueType,
  kneeCircumferenceRight: MeasurementValueType,
  kneeCircumferenceLeft: MeasurementValueType,
  shoulderLengthRight: MeasurementValueType,
  shoulderLengthLeft: MeasurementValueType,
  ankleCircumferenceRight: MeasurementValueType,
  ankleCircumferenceLeft: MeasurementValueType,
  seatAngle: MeasurementValueType,
  shoulderAngleRight: MeasurementValueType,
  shoulderAngleLeft: MeasurementValueType,
  stomachCircumference: MeasurementValueType,
  stomachMaxCircumference: MeasurementValueType,
  abdomenCircumference: MeasurementValueType,
  sevenCmAboveWaistStomachCircumference: MeasurementValueType,
  eightCmBelowWaistCircumference: MeasurementValueType,
  tenCmBelowWaistCircumference: MeasurementValueType,
  frontWaistLength: MeasurementValueType,
  bustDistance: MeasurementValueType,
  acrossFrontChestWidth: MeasurementValueType,
  hipHeightLeft: MeasurementValueType,
  hipHeightRight: MeasurementValueType,
  kneeHeightLeft: MeasurementValueType,
  kneeHeightRight: MeasurementValueType,
  armholeDepthLeft: MeasurementValueType,
  armholeDepthRight: MeasurementValueType,
  shoulderDepthLeft: MeasurementValueType,
  shoulderDepthRight: MeasurementValueType,
  underarmWidthLeft: MeasurementValueType,
  underarmWidthRight: MeasurementValueType,
  frontHipWidth: MeasurementValueType,
  backHipWidth: MeasurementValueType,
  kneeWidthLeft: MeasurementValueType,
  kneeWidthRight: MeasurementValueType,
  bicepWidthLeft: MeasurementValueType,
  bicepWidthRight: MeasurementValueType,
  ankleWidthLeft: MeasurementValueType,
  ankleWidthRight: MeasurementValueType,
  abdomenSeatWidth: MeasurementValueType,
  belowKneeCircumferenceLeft: MeasurementValueType,
  belowKneeCircumferenceRight: MeasurementValueType,
  stomachSeatWidth: MeasurementValueType,
  waistToWaistOverCrotch: MeasurementValueType,
  underbustCircumference: MeasurementValueType,
  shoulderSlopeRight: MeasurementValueType,
  shoulderSlopeLeft: MeasurementValueType,
  forwardProjectionShoulderRight: MeasurementValueType,
  forwardProjectionShoulderLeft: MeasurementValueType,
  waistToAbdomenProjection: MeasurementValueType,
  waistToSeatProjection: MeasurementValueType,
  waistToSeatTangentAngle: MeasurementValueType,
  waistToHipTangentAngleRight: MeasurementValueType,
  waistToHipTangentAngleLeft: MeasurementValueType,
  napeToMaxShoulderBladeAngle: MeasurementValueType,
  napeToTangentShoulderBladeAngle: MeasurementValueType,
  hipDiameterFront: MeasurementValueType,
  hipDiameterSide: MeasurementValueType,
  upperBustGirth: MeasurementValueType,
  chestCircumferenceOnShoulderBlade: MeasurementValueType,
  scyeDepthObliquelyRight: MeasurementValueType,

  [k: string]: MeasurementValueType,
};

export type IBodyMeasurementPaths = {
  bodyHeight: MeasurementPathType,
  innerLegLength: MeasurementPathType,
  waistCircumference: MeasurementPathType,
  armLength: MeasurementPathType,
  chestCircumference: MeasurementPathType,
  hipCircumference: MeasurementPathType,
  neckToGround: MeasurementPathType,
  centerBackLength: MeasurementPathType,
  beltlineCircumference: MeasurementPathType,
  innerLegLengthRight: MeasurementPathType,
  innerLegLengthLeft: MeasurementPathType,
  napeToBeltlineBackLength: MeasurementPathType,
  calfCircumferenceRight: MeasurementPathType,
  calfCircumferenceLeft: MeasurementPathType,
  neckCircumference: MeasurementPathType,
  acrossBackWidth: MeasurementPathType,
  neckHeight: MeasurementPathType,
  napeToWristRight: MeasurementPathType,
  napeToWristLeft: MeasurementPathType,
  armLengthRight: MeasurementPathType,
  armLengthLeft: MeasurementPathType,
  thighCircumferenceRight: MeasurementPathType,
  thighCircumferenceLeft: MeasurementPathType,
  bicepCircumferenceRight: MeasurementPathType,
  bicepCircumferenceLeft: MeasurementPathType,
  napeToScyeDistance: MeasurementPathType,
  napeToWaistBackDistance: MeasurementPathType,
  napeToBustLength: MeasurementPathType,
  napeToWaistOverBustLength: MeasurementPathType,
  waistToHipDistanceRight: MeasurementPathType,
  waistToHipDistanceLeft: MeasurementPathType,
  waistToFloorLengthRight: MeasurementPathType,
  waistToFloorLengthLeft: MeasurementPathType,
  acrossBustLength: MeasurementPathType,
  scyeWidthRight: MeasurementPathType,
  scyeWidthLeft: MeasurementPathType,
  acromionWidth: MeasurementPathType,
  cupDepthRight: MeasurementPathType,
  cupDepthLeft: MeasurementPathType,
  waistToKneeLengthRight: MeasurementPathType,
  waistToKneeLengthLeft: MeasurementPathType,
  acromionToElbowLengthRight: MeasurementPathType,
  acromionToElbowLengthLeft: MeasurementPathType,
  highHipCircumference: MeasurementPathType,
  wristCircumferenceRight: MeasurementPathType,
  wristCircumferenceLeft: MeasurementPathType,
  kneeCircumferenceRight: MeasurementPathType,
  kneeCircumferenceLeft: MeasurementPathType,
  shoulderLengthRight: MeasurementPathType,
  shoulderLengthLeft: MeasurementPathType,
  ankleCircumferenceRight: MeasurementPathType,
  ankleCircumferenceLeft: MeasurementPathType,
  seatAngle: MeasurementPathType,
  shoulderAngleRight: MeasurementPathType,
  shoulderAngleLeft: MeasurementPathType,
  stomachCircumference: MeasurementPathType,
  stomachMaxCircumference: MeasurementPathType,
  abdomenCircumference: MeasurementPathType,
  sevenCmAboveWaistStomachCircumference: MeasurementPathType,
  eightCmBelowWaistCircumference: MeasurementPathType,
  tenCmBelowWaistCircumference: MeasurementPathType,
  frontWaistLength: MeasurementPathType,
  bustDistance: MeasurementPathType,
  acrossFrontChestWidth: MeasurementPathType,
  hipHeightLeft: MeasurementPathType,
  hipHeightRight: MeasurementPathType,
  kneeHeightLeft: MeasurementPathType,
  kneeHeightRight: MeasurementPathType,
  armholeDepthLeft: MeasurementPathType,
  armholeDepthRight: MeasurementPathType,
  shoulderDepthLeft: MeasurementPathType,
  shoulderDepthRight: MeasurementPathType,
  underarmWidthLeft: MeasurementPathType,
  underarmWidthRight: MeasurementPathType,
  frontHipWidth: MeasurementPathType,
  backHipWidth: MeasurementPathType,
  kneeWidthLeft: MeasurementPathType,
  kneeWidthRight: MeasurementPathType,
  bicepWidthLeft: MeasurementPathType,
  bicepWidthRight: MeasurementPathType,
  ankleWidthLeft: MeasurementPathType,
  ankleWidthRight: MeasurementPathType,
  abdomenSeatWidth: MeasurementPathType,
  belowKneeCircumferenceLeft: MeasurementPathType,
  belowKneeCircumferenceRight: MeasurementPathType,
  stomachSeatWidth: MeasurementPathType,
  waistToWaistOverCrotch: MeasurementPathType,
  underbustCircumference: MeasurementPathType,
  shoulderSlopeRight: MeasurementPathType,
  shoulderSlopeLeft: MeasurementPathType,
  forwardProjectionShoulderRight: MeasurementPathType,
  forwardProjectionShoulderLeft: MeasurementPathType,
  waistToAbdomenProjection: MeasurementPathType,
  waistToSeatProjection: MeasurementPathType,
  waistToSeatTangentAngle: MeasurementPathType,
  waistToHipTangentAngleRight: MeasurementPathType,
  waistToHipTangentAngleLeft: MeasurementPathType,
  napeToMaxShoulderBladeAngle: MeasurementPathType,
  napeToTangentShoulderBladeAngle: MeasurementPathType,
  hipDiameterFront: MeasurementPathType,
  hipDiameterSide: MeasurementPathType,
  upperBustGirth: MeasurementPathType,
  chestCircumferenceOnShoulderBlade: MeasurementPathType,
  scyeDepthObliquelyRight: MeasurementPathType,

  [k: string]: MeasurementPathType,
};

export type IBodyFeaturesSnapshot = {
  id: string,
  gender: Gender,
  bodyUrl: string,
  avatarUrl: string,
  processType: string,
  measurements: IBodyMeasurements,
  measurementPaths: IBodyMeasurementPaths | null,
  betas: number[],
};

export type IIdentifierSizeLabelMap = { [key: string]: string };

export type IPlatformParentFrame = {
  showIFrame: (enabled: boolean) => void,
  triggerNotification: (notification: PlatformNotification) => void,
  mapIdentifiersToSizeLabels: (identifiers: string[]) => Promise<IIdentifierSizeLabelMap>,
  getReferrerUrl: () => string,
};

export type PlatformNotification = NewProfileNotification
| UserStateChangedNotification
| NewSizeRecommendationsNotification
| OverlayClosedNotification;

type NewProfileNotification = {
  type: 'newProfile',
  profile: IRawUserProfile,
};

type OverlayClosedNotification = {
  type: 'overlayClosed',
};

export type UserStateChangedNotification = {
  type: 'userStateChanged',
  newUserState: UserStateExternal,
};

export type UserStateExternal = SignedOut | SignedInWithoutProfile | SignedInComplete;

type SignedOut = {
  type: 'SignedOut',
};

type SignedInWithoutProfile = {
  type: 'SignedInWithoutProfile',
};

type SignedInComplete = {
  type: 'SignedInComplete',
  latestBodySnapshot: IRawUserProfile,
};

type NewSizeRecommendationsNotification = {
  type: 'newSizeRecommendations',
  recommendations: { [variantId: string]: IRecommendation },
};

export type IRawUserProfile = {
  id: string,
  processType: string,
  userId: string,
  loginName: string,
  gender: Gender,
  measurements: IBodyMeasurements,
  measurementPaths: IBodyMeasurementPaths | null,
  bodyRepresentationURL: string,
  avatarRepresentationURL: string,
  betas: number[],
};

export type ILegalDocumentLink = {
  text: string,
  url: string,
};

export type ILegalInformation = {
  company_name: string,
  document_links: ILegalDocumentLink[],
};

export type ApiKeyData = {
  api_key: string,
  allowed_origins: string[],
  description: string,
  tenant_id: string,
  features: {
    allow_quick_size?: boolean,
    allow_operator_mode?: boolean,
    allow_callback_changes?: boolean,
  },
  legal_information: ILegalInformation | null,
};

export type UseCase = SizeRecommendation
| CustomTailoring
| Vdr
| Workwear;

type SizeRecommendation = {
  readonly type: 'sizeRecommendation',
};

type CustomTailoring = {
  readonly type: 'customTailoring',
};

type Vdr = {
  readonly type: 'vdr',
};

type Workwear = {
  readonly type: 'workwear',
};
