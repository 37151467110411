import * as React from 'react';

import { OutlinedSVG } from '../DressingRoomTheme';

const NextArrow = (props: any) => (
  <OutlinedSVG
    width={26}
    height={26}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <title>BTN_NEXT_NEG</title>
    <defs>
      <rect id="b" width={26} height={26} rx={13} />
      <filter
        x="-23.1%"
        y="-23.1%"
        width="146.2%"
        height="146.2%"
        filterUnits="objectBoundingBox"
        id="a"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.492 7.17l5.706 5.707-5.706 5.836"
      />
    </g>
  </OutlinedSVG>
);

export default NextArrow;
