/* eslint-disable react/require-default-props */
import * as React from 'react';
import styled from 'styled-components';

import { Button } from '../../dressing-room/lib/dressingRoom/DressingRoomTheme';

import CloseButton from './dialog_close';
import DialogSeparator from './dialog_notch';

export const IntroDialog = (props: {
  meeplButtonClicked: () => void,
}) => (
  <Modal>
    <s.modalTop>
      <s.title>
        Welcome to the Alphatauri Virtual Fitting Room
      </s.title>
      <s.text>
        Use our innovative measurement technology to ensure the perfect fit for your personalised 3D Knit products.
      </s.text>
      <s.text>
        It’s simple: install the Meepl app on your phone by scanning the QR code in the next step
        then follow the steps within the app to create and import your very own 3D avatar.
        Once your 3D avatar is imported, you can browse our different 3D knit styles.
        After making your selection, print a QR code to get your made-to-measure 3D Knit product.
      </s.text>
      <s.text>
        If you have any questions, please reach out to our staff, they are very happy to help.
      </s.text>
    </s.modalTop>
    <DialogSeparator />
    <s.modalBottom>
      <s.dialogButton
        onClick={props.meeplButtonClicked}
      >
        Create your avatar
      </s.dialogButton>
    </s.modalBottom>
  </Modal>
);

export const SignOutDialog = (props: {
  signOut: (doSignOut: boolean) => void,
}) => (
  <Modal
    onClose={() => props.signOut(false)}
  >
    <s.modalTop>
      <s.title>
        Remove avatar from fitting room?
      </s.title>
      <s.text>
        Do you really want to remove your avatar?
      </s.text>
    </s.modalTop>
    <DialogSeparator />
    <s.modalBottom>
      <s.buttonsContainer>
        <s.dialogButtonSecondary
          onClick={() => props.signOut(false)}
        >
          No
        </s.dialogButtonSecondary>
        <s.dialogButton
          onClick={() => props.signOut(true)}
        >
          Yes
        </s.dialogButton>
      </s.buttonsContainer>
    </s.modalBottom>
  </Modal>
);

export const PrintedDialog = (props: {
  signOut: (doSignOut: boolean) => void,
}) => (
  <Modal
    onClose={() => props.signOut(false)}
  >
    <s.modalTop>
      <s.title>
        Thanks, your QR code will be printed shortly
      </s.title>
      <s.text>
        Thanks for exploring the innovation of made-to-measure fashion.
        In order to buy the product,
        simply scan the supplied QR code with your mobile device
        and go to the check out in our web shop.
        We hope to see you again soon.
      </s.text>
    </s.modalTop>
    <DialogSeparator />
    <s.modalBottom>
      <s.buttonsContainer>
        <s.dialogButton
          onClick={() => props.signOut(true)}
        >
          Close session and remove my avatar
        </s.dialogButton>
      </s.buttonsContainer>
    </s.modalBottom>
  </Modal>
);

export const BodyScanCompletedDialog = (props: {
  onClose: () => void,
}) => (
  <Modal>
    <s.modalTop>
      <s.title>
        3D avatar imported successfully
      </s.title>
      <s.text>
        Great, now that your avatar is imported,
        you can preview different products by tapping them at the bottom of the screen.
        You can also rotate your avatar to see the product from every angle.
      </s.text>
    </s.modalTop>
    <DialogSeparator />
    <s.modalBottom>
      <s.buttonsContainer>
        <s.dialogButton
          onClick={props.onClose}
        >
          Get started
        </s.dialogButton>
      </s.buttonsContainer>
    </s.modalBottom>
  </Modal>
);

const Modal = (props: {
  children: React.ReactNode,
  onClose?: () => void,
}) => (
  <>
    <s.milkGlass />
    <s.centered>
      <s.modalWindow>
        {props.children}
      </s.modalWindow>
      {props.onClose && (
      <s.closeButtonContainer>
        <CloseButton
          onClick={props.onClose}
        />
      </s.closeButtonContainer>
      )}
    </s.centered>
  </>
);

const s = {
  title: styled.div`
    font-size: 20px;
    text-transform: uppercase;
  `,
  text: styled.div`
    margin-top: 10px;
    font-size: 16px;
  `,
  modalWindow: styled.div`
    min-width: 300px;
    max-width: 490px;
    pointer-events: auto;
  `,
  modalTop: styled.div`
    background-color: rgb(255, 255, 255);
    padding: 20px;
    pointer-events: auto;
    border-radius: 4px 4px 0 0;
    border-style: none;
  `,
  modalBottom: styled.div`
    background-color: rgb(255, 255, 255);
    padding: 20px;
    pointer-events: auto;
    border-radius: 0 0 4px 4px;
    border-style: none;
  `,
  centered: styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  milkGlass: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: auto;
  `,
  buttonContainer: styled.div`
    margin-top: 20px;
    text-align: center;
  `,
  dialogButton: styled(Button)`
    background-color: rgb(29, 29, 27);
    color: rgb(255, 255, 255);
    opacity: 1.0;
    cursor: pointer;
    width: 100%;
  `,
  buttonsContainer: styled.div`
    display: flex;
  `,
  dialogButtonSecondary: styled(Button)`
    background-color: rgb(255, 255, 255);
    color: rgb(29, 29, 27);
    opacity: 1.0;
    cursor: pointer;
    margin-right: 20px;
    width: 100%;
  `,
  closeButtonContainer: styled.div`
    position: absolute;
    top: -24px;
    right: -24px;
    pointer-events: auto;
  `,
};
