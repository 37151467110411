import * as Jimp from 'jimp';
import * as qrcode from 'qrcode';
import {Modal} from 'antd';
import {render} from 'react-dom';

export type QrCodePrintingState = 'ready' | 'isPrinting' | 'hasPrinted' | 'failed';

export const qrCodeText = (qrCodeState: QrCodePrintingState) => ({
  ready: 'Click QR code for printing.',
  failed: 'Printing failed.',
  isPrinting: 'Sending...',
  hasPrinted: 'Sent to printer.',
}[qrCodeState]);

export const sendQRCode = async (data: string, url: string) => {
  const canvas = document.createElement('canvas');
  const qrCodeSize = 259;
  qrcode.toCanvas(
    canvas,
    data,
    {
      width: qrCodeSize,
      margin: 0,
    },
    error => {
      if (error) {
        throw error;
      }
    },
  );

  const card = document.createElement('canvas');
  card.width = 1012;
  card.height = 636;
  const context = card.getContext('2d');
  if (!context) {
    throw new Error('Could not create context');
  }
  context.fillStyle = 'rgb(255, 255, 255)';
  context.fillRect(0, 0, card.width, card.height);

  context.fillStyle = 'rgb(0, 0, 0)';
  context.drawImage(canvas, 0.5 * (card.width - qrCodeSize), 228);

  context.font = '23px sans-serif';
  context.fillText('VALID FOR A LIMITED TIME ONLY', 88, 566);

  const base64png = card.toDataURL('image/png');
  const image = await Jimp.read(base64png);
  const buffer = await image.getBufferAsync('image/bmp');

  const formdata = new FormData();
  const blob = new Blob([buffer], { type: 'image/bmp' });
  formdata.append('file', blob, 'damian wizytkowaka.bmp');
  formdata.append('token', 'DamianToken');


  var img = new Image();
  img.id = 'qrCode';
  img.src = base64png;
  img.setAttribute('style', 'max-width: 100%; max-height: 100%;')
  document.getElementById('qrContainer').append(img);
  console.log('printing!');
  await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'text/html,'
      + 'application/xhtml+xml,'
      + 'application/xml;q=0.9,'
      + 'image/webp,'
      + 'image/apng,*/*;q=0.8,'
      + 'application/signed-exchange;v=b3;q=0.9',
    },
    body: formdata,
  });
};
