import * as THREE from 'three';

export type ISkyDomeConfig = {
  floor: string,
  floorColor: THREE.Color,
  sky: string,
  skyColor: THREE.Color,
  radius: number,
};

export const skyConfigurations: { [key: string]: ISkyDomeConfig} = {
  realistic: {
    floor: '0.65 + 0.35 * pow(d, 0.5)',
    floorColor: new THREE.Color('rgb(255, 255, 255)'),
    sky: '0.7 + 0.3 * pow(1.0 - yn, 3.0)',
    skyColor: new THREE.Color('rgb(255, 255, 255)'),
    radius: 100,
  },
  darkHorizon: {
    floor: '0.95 - 0.25 * pow(d, 0.7)',
    floorColor: new THREE.Color('rgb(255, 255, 255)'),
    sky: '0.97 - 0.27 * pow(1.0 - yn, 10.0)',
    skyColor: new THREE.Color('rgb(255, 255, 255)'),
    radius: 200,
  },
  studio: {
    floor: '0.7 + 0.27 * pow(1.0 - d, 0.7)',
    floorColor: new THREE.Color('rgb(255, 255, 255)'),
    sky: '0.97 - 0.27 * pow(1.0 - yn, 3.0)',
    skyColor: new THREE.Color('rgb(255, 255, 255)'),
    radius: 5,
  },
  meepl: {
    floor: '1.0',
    floorColor: new THREE.Color('rgb(255, 242, 236)'),
    sky: '1.0',
    skyColor: new THREE.Color('rgb(255, 242, 236)'),
    radius: 5,
  },
};

const skyDome = (configuration: ISkyDomeConfig) => {
  const groundLevel = 0;

  const floorPlane = new THREE.PlaneGeometry(
    configuration.radius * 2.0,
    configuration.radius * 2.0,
    4,
    4,
  );

  const floorMaterial = new THREE.ShaderMaterial({
    uniforms: {
      radius: { value: configuration.radius },
    },
    vertexShader: [
      'precision highp float;',
      'varying vec2 xz;',
      'void main() {',
      'xz = position.xy;',
      'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
      '}',
    ].join('\n'),
    fragmentShader: [
      'precision highp float;',
      'uniform float radius;',
      'varying vec2 xz;',
      'void main() {',
      'float d = length(xz) / radius;',
      'if (d > 1.0) discard;',
      `float brightnessR = ${configuration.floor} * ${configuration.floorColor.r.toFixed(3)};`,
      `float brightnessG = ${configuration.floor} * ${configuration.floorColor.g.toFixed(3)};`,
      `float brightnessB = ${configuration.floor} * ${configuration.floorColor.b.toFixed(3)};`,
      'gl_FragColor = vec4(brightnessR, brightnessG, brightnessB, 1.0);',
      '}',
    ].join('\n'),
  });

  const floor = new THREE.Mesh(
    floorPlane,
    floorMaterial,
  );
  floor.rotation.x = -Math.PI / 2.0;

  const floorShadow = new THREE.Mesh(
    floorPlane,
    new THREE.ShadowMaterial({ opacity: 0.1 }),
  );
  floorShadow.translateY(0.01);
  floorShadow.rotation.x = -Math.PI / 2.0;
  floorShadow.receiveShadow = true;

  const sphere = new THREE.SphereGeometry(configuration.radius, 32, 16);

  const domeMaterial = new THREE.ShaderMaterial({
    uniforms: {
      radius: { value: configuration.radius },
    },
    vertexShader: [
      'precision highp float;',
      'varying float y;',
      'void main() {',
      'y = position.y;',
      'gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);',
      '}',
    ].join('\n'),
    fragmentShader: [
      'precision highp float;',
      'uniform float radius;',
      'varying float y;',
      'void main() {',
      'float yn = max(y / radius, 0.0);',
      `float brightnessR = ${configuration.sky} * ${configuration.floorColor.r.toFixed(3)};`,
      `float brightnessG = ${configuration.sky} * ${configuration.floorColor.g.toFixed(3)};`,
      `float brightnessB = ${configuration.sky} * ${configuration.floorColor.b.toFixed(3)};`,
      'gl_FragColor = vec4(brightnessR, brightnessG, brightnessB, 1.0);',
      '}',
    ].join('\n'),
  });
  domeMaterial.side = THREE.BackSide;

  const dome = new THREE.Mesh(sphere, domeMaterial);

  const group = new THREE.Group();
  group.translateY(groundLevel);
  group.add(floor);
  group.add(floorShadow);
  group.add(dome);

  return group;
};

export default skyDome;
