import * as React from 'react';
import styled from 'styled-components';

import { Gender, IntendedGender } from '../../fision-platform-interface';
import { IProductGroups } from '../../model/common';

import NextArrow from './NextArrow';
import PreviousArrow from './PreviousArrow';
import RemoveButton from './RemoveButton';

const disableContextMenu = (event: any) => {
  event.preventDefault();
  event.stopPropagation();
  return false;
};

type IProps = {
  products: IProductGroups,
  onRemoveFromVariantList?: (id: string) => void,
  gender: Gender | null,
};

type IState = {
  dragging: boolean,
  draggingStoppedTime: number,
  dragStartX: number,
  sliderDraggingOffset: number,
  sliderOffset: number,
  sliderWidth: number,
  removalMode: boolean,
};

const sliderEntryWidth = 140;

export const isGenderMatch = (intendedGender: IntendedGender, gender: Gender | null) => (
  intendedGender === IntendedGender.Unisex
  || gender === Gender.Other
  || (gender === Gender.Female && intendedGender === IntendedGender.Female)
  || (gender === Gender.Male && intendedGender === IntendedGender.Male)
);

export default class ObjectSelector extends React.Component<IProps, IState> {
  private sliderElement: any;

  constructor(props: IProps) {
    super(props);

    const group = props.products.groups.find(entry => entry.groupId === props.products.selectedGroupId);
    const sliderOffset = group
      ? -sliderEntryWidth * props.products.groups.indexOf(group)
      : 0;

    this.state = {
      dragging: false,
      draggingStoppedTime: 0,
      dragStartX: 0,
      sliderDraggingOffset: 0,
      sliderOffset,
      sliderWidth: 0,
      removalMode: false,
    };

    this.sliderElement = React.createRef();
    this.onResize = this.onResize.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  public componentDidMount(): void {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState): void {
    const {
      dragging,
    } = this.state;

    if (dragging && !prevState.dragging) {
      window.addEventListener('mousemove', this.onMouseMove);
      window.addEventListener('mouseup', this.onMouseUp);
      window.addEventListener('touchmove', this.onTouchMove);
      window.addEventListener('touchend', this.onTouchEnd);
    } else if (!dragging && prevState.dragging) {
      window.removeEventListener('mousemove', this.onMouseMove);
      window.removeEventListener('mouseup', this.onMouseUp);
      window.removeEventListener('touchmove', this.onTouchMove);
      window.removeEventListener('touchend', this.onTouchEnd);
    }
  }

  public render() {
    const {
      products,
      onRemoveFromVariantList,
      gender,
    } = this.props;
    const {
      dragging,
      sliderDraggingOffset,
      draggingStoppedTime,
      sliderWidth,
      removalMode,
    } = this.state;

    const sliderOffset = this.getSliderOffset();

    return (
      <s.slider.container>
        <s.slider.arrow
          visible={sliderOffset < 0}
          onClick={() => {
            this.moveSlider(-1);
          }}
        >
          <PreviousArrow />
        </s.slider.arrow>
        <s.slider.entries>
          <LongPress
            activationTimeInMs={1000}
            onActivate={() => {
              this.setState({
                removalMode: true,
              });
            }}
          >
            <s.slider.mask>
              <s.slider.entryContainer
                animate={!dragging}
                ref={this.sliderElement}
                onMouseDown={this.onMouseDown}
                onTouchStart={this.onTouchStart}
                style={{
                  left: `${sliderOffset}px`,
                }}
              >
                {products.groups.map(selectableObject => {
                  const isSelected = selectableObject.groupId === products.selectedGroupId;

                  const genderMatching = isGenderMatch(selectableObject.intendedGender, gender);

                  const select = () => {
                    if (!isSelected
                      && (sliderDraggingOffset === 0 || (!dragging && Date.now() > draggingStoppedTime + 1000))) {
                      this.setState({
                        removalMode: false,
                      });
                      products.select(selectableObject.groupId);
                    }
                  };

                  return (
                    <s.slider.entry
                      key={selectableObject.variantIds[0]}
                    >
                      <s.object.container>
                        <s.object.image
                          src={selectableObject.imageUrl}
                          onClick={select}
                          onTouchEnd={select}
                          isSelected={isSelected}
                          opacity={genderMatching ? 1.0 : 0.3}
                          onContextMenu={disableContextMenu}
                        />
                        { onRemoveFromVariantList
                          ? (
                            <s.object.removeButton isVisible={removalMode}>
                              <RemoveButton
                                onClick={() => {
                                  selectableObject.variantIds.forEach(variantId => {
                                    onRemoveFromVariantList(variantId);
                                  });
                                }}
                              />
                            </s.object.removeButton>
                          )
                          : null }
                      </s.object.container>
                    </s.slider.entry>
                  );
                })}
              </s.slider.entryContainer>
            </s.slider.mask>
          </LongPress>
        </s.slider.entries>
        <s.slider.arrow
          visible={products.groups.length * sliderEntryWidth + sliderOffset > sliderWidth}
          onClick={() => {
            this.moveSlider(1);
          }}
        >
          <NextArrow />
        </s.slider.arrow>
      </s.slider.container>
    );
  }

  private onResize() {
    if (this.sliderElement && this.sliderElement.current) {
      this.setState({
        sliderWidth: this.sliderElement.current.offsetWidth,
      });
    }
  }

  private startDrag(screenX: number) {
    this.setState({
      dragging: true,
      dragStartX: screenX,
      sliderDraggingOffset: 0,
    });
  }

  private onMouseDown(event: React.MouseEvent) {
    if (event.button !== 0) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    this.startDrag(event.screenX);
  }

  private onTouchStart(event: React.TouchEvent) {
    if (event.touches.length !== 1) {
      return;
    }
    this.startDrag(event.touches[0].screenX);
  }

  private endDrag() {
    const {
      sliderDraggingOffset,
      sliderOffset,
    } = this.state;

    this.setState({
      dragging: false,
      draggingStoppedTime: Date.now(),
      sliderOffset: sliderOffset + sliderDraggingOffset,
    });
  }

  private onMouseUp(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.endDrag();
  }

  private onTouchEnd() {
    this.endDrag();
  }

  private drag(screenX: number) {
    const {
      dragging,
      dragStartX,
      sliderOffset,
    } = this.state;

    if (!dragging) {
      return;
    }

    const deltaX = screenX - dragStartX;
    const limitedDelta = this.limitSlider(sliderOffset + deltaX) - sliderOffset;

    this.setState({
      sliderDraggingOffset: limitedDelta,
    });
  }

  private onMouseMove(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.drag(event.screenX);
  }

  private onTouchMove(event: TouchEvent) {
    if (event.touches.length !== 1) {
      return;
    }
    this.drag(event.touches[0].screenX);
  }

  private limitSlider(offset: number): number {
    const {
      products,
    } = this.props;
    const {
      sliderWidth,
    } = this.state;

    if (sliderWidth === 0) { return 0; }
    const entriesWidth = products.groups.length * sliderEntryWidth;
    if (entriesWidth < sliderWidth) { return (sliderWidth - entriesWidth) / 2.0; }

    return Math.min(0, Math.max(-entriesWidth + sliderWidth, offset));
  }

  private moveSlider(noOfEntries: number) {
    const {
      sliderOffset,
    } = this.state;

    const offsetNorm = (sliderOffset - noOfEntries * sliderEntryWidth) / sliderEntryWidth;
    const roundedOffsetNorm = noOfEntries > 0 ? Math.floor(offsetNorm) : Math.ceil(offsetNorm);
    this.setState({ sliderOffset: this.limitSlider(roundedOffsetNorm * sliderEntryWidth) });
  }

  private getSliderOffset() {
    const {
      dragging,
      sliderDraggingOffset,
      sliderOffset,
    } = this.state;

    const offset = sliderOffset + (dragging ? sliderDraggingOffset : 0);

    return this.limitSlider(offset);
  }
}

const s = {
  slider: {
    container: styled.div`
      display: flex;
      align-items: center;
      min-height: 140px;
      box-sizing: border-box;
      width: 100%;
    `,

    arrow: styled.div<{ visible: boolean }>`
      visibility: ${props => (props.visible ? 'visible' : 'hidden')};
      cursor: pointer;
      height: 26px;
      padding-top: 57px;
      padding-bottom: 57px;
    `,

    entries: styled.div`
      width: calc(100% - 52px);
    `,

    mask: styled.div`
      overflow: hidden;
    `,

    entryContainer: styled.div<{animate: boolean}>`
      display: flex;
      position: relative;
      transition: ${props => (props.animate ? 'left 0.5s' : 'none')};
    `,

    entry: styled.div`
      width: 140px;
      height: 140px;
    `,
  },

  object: {
    container: styled.div`
      position: relative;
      margin: 0;
      outline: none;
      width: 140px;
      height: 140px;
      user-select: none;

      @media (hover: hover) {
        &:hover div {
          visibility: visible;
        }
      }
    `,

    image: styled.img<{ isSelected: boolean, opacity: number }>`
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      width: 140px;
      max-height: 140px;
      cursor: ${props => (props.isSelected ? 'auto' : 'pointer')};
      filter: ${props => {
    const brightness = 128 * props.opacity;
    return props.isSelected ? `drop-shadow(0 0 8px rgb(${brightness}, ${brightness}, ${brightness}))` : 'none';
  }
};
      opacity: ${props => props.opacity};
      -webkit-touch-callout: none;
    `,

    removeButton: styled.div<{ isVisible: boolean}>`
      position: absolute;
      right: 10px;
      top: 10px;
      visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
    `,
  },
};

const LongPress = (props: {
  activationTimeInMs: number,
  onActivate: () => void,
  children: JSX.Element[] | JSX.Element,
}) => {
  const [timeOuts] = React.useState<NodeJS.Timeout[]>([]);

  const clear = () => {
    while (timeOuts.length > 0) {
      const timeOut = timeOuts.pop();
      if (timeOut != null) {
        clearTimeout(timeOut);
      }
    }
  };

  return (
    <div
      onTouchStart={() => {
        timeOuts.push(setTimeout(props.onActivate, props.activationTimeInMs) as any);
      }}
      onTouchEnd={clear}
      onTouchMove={clear}
    >
      {props.children}
    </div>
  );
};
