import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2';

import { ISimulationProcess } from '../../fision-platform-interface';
import { fetchRepeatingOnServerErrors } from '../../shared/truncated_exponential_backoff';

export const loadObj = async (
  url: string,
): Promise<THREE.Object3D> => {
  const response = await fetchRepeatingOnServerErrors(url);
  const text = await response.text();
  return new OBJLoader2()
    .setUseIndices(true)
    .parse(text);
};

export const loadObjWithPreProcessor = async (
  url: string,
  preprocessor: (objContent: string) => string,
): Promise<THREE.Object3D> => {
  const response = await fetchRepeatingOnServerErrors(url);
  const text = preprocessor(await response.text());
  return new OBJLoader2()
    .setUseIndices(true)
    .parse(text);
};

export const loadGLB = async (
  url: string,
): Promise<THREE.Object3D> => new Promise((resolve, reject) => {
  new GLTFLoader().load(
    url,
    gltf => {
      const scene = gltf.scene || gltf.scenes[0];
      resolve(scene);
    },
    undefined,
    reject,
  );
});

export const loadGltf = (
  url: string,
  material?: THREE.Material,
  insideMaterial?: THREE.Material,
): Promise<THREE.Object3D> => new Promise((resolve, reject) => {
  const addMaterials = (scene: THREE.Scene) => {
    const insideMeshes: Array<{ parent: THREE.Object3D, mesh: THREE.Mesh }> = [];

    scene.traverse(node => {
      if (node.type !== 'Mesh') {
        return;
      }
      const mesh = node as THREE.Mesh;

      if (insideMaterial) {
        const insideMesh = mesh.clone();
        insideMesh.material = insideMaterial;
        insideMesh.material.side = THREE.BackSide;
        insideMesh.receiveShadow = true;
        insideMesh.castShadow = false;
        if (mesh.parent) {
          insideMeshes.push({ parent: mesh.parent, mesh: insideMesh });
        }
      }

      if (material) {
        mesh.material = material;
      }
      mesh.receiveShadow = true;
      mesh.castShadow = true;
    });

    insideMeshes.forEach(entry => entry.parent.add(entry.mesh));
  };

  fetch(url)
    .then(response => response.arrayBuffer())
    .then(buffer => {
      new GLTFLoader().parse(buffer, '', gltf => {
        addMaterials(gltf.scene);
        resolve(gltf.scene);
      });
    })
    .catch(() => {
      reject(new Error(`Error loading 3d model from ${url}`));
    });
});

export const loadTexture = (url: string): Promise<THREE.Texture> => new Promise((resolve, reject) => {
  new THREE.TextureLoader().load(url, texture => {
    resolve(texture);
  }, undefined, reject);
});

const loadBody = (meshUrl: string): Promise<THREE.Object3D> => {
  const bodyMaterial = new THREE.MeshStandardMaterial({
    color: new THREE.Color('rgb(200, 200, 200)'),
    metalness: 0.0,
    roughness: 0.5,
  });
  return loadGltf(meshUrl, bodyMaterial);
};

const loadGarment = (
  meshUrl: string,
  outsideTextureUrl: string,
  insideTextureUrl: string,
): Promise<THREE.Object3D> => (
  loadTexture(outsideTextureUrl)
    .then(outsideTexture => {
      // eslint-disable-next-line no-param-reassign
      outsideTexture.flipY = false;
      const outsideMaterial = new THREE.MeshStandardMaterial({
        map: outsideTexture,
        metalness: 0.0,
        roughness: 1.0,
      });

      return loadTexture(insideTextureUrl)
        .then(insideTexture => {
          // eslint-disable-next-line no-param-reassign
          insideTexture.flipY = false;
          const insideMaterial = new THREE.MeshStandardMaterial({
            map: insideTexture,
            metalness: 0.0,
            roughness: 1.0,
          });

          return loadGltf(meshUrl, outsideMaterial, insideMaterial);
        });
    })
);

export const loadFromGarmentSimulationResult = (simulationResult: ISimulationProcess): Promise<THREE.Object3D> => {
  const {
    body,
    upperBodyGarment,
    lowerBodyGarment,
  } = simulationResult;
  if (!body || !upperBodyGarment || !lowerBodyGarment) {
    throw new Error('Incomplete simulation result');
  }

  const meshes = [
    loadBody(body.meshUrl),
    loadGarment(lowerBodyGarment.meshUrl, lowerBodyGarment.outsideTextureUrl, lowerBodyGarment.insideTextureUrl),
    loadGarment(upperBodyGarment.meshUrl, upperBodyGarment.outsideTextureUrl, upperBodyGarment.insideTextureUrl),
  ];

  return Promise.all(meshes)
    .then(results => {
      const group = new THREE.Group();
      results.forEach(mesh => {
        group.add(mesh);
      });

      const boundingBox = new THREE.Box3().setFromObject(group);
      group.translateY(-boundingBox.min.y);

      return group;
    });
};
