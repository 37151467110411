import * as React from 'react';
import styled from 'styled-components';

const DialogSeparator = () => (
  <s.separator>
    <FancyDialogNotch rightSide={false} id="notchLeft" />
    <s.middle>
      <s.line />
    </s.middle>
    <FancyDialogNotch rightSide id="notchRight" />
  </s.separator>
);

export default DialogSeparator;

const FancyDialogNotch = (props: {
  rightSide: boolean,
  id: string,
}) => (
  <s.notch
    width={16}
    height={16}
  >
    <mask id={props.id}>
      <rect
        width={16}
        height={16}
        fill="white"
        stroke="white"
      />
      <circle
        cx={props.rightSide ? 16 : 0}
        cy={8}
        r={6}
        fill="black"
        stroke="white"
      />
    </mask>
    <rect
      width={16}
      height={16}
      style={{ fill: 'rgb(255, 255, 255)' }}
      mask={`url(#${props.id})`}
    />
  </s.notch>
);

const s = {
  notch: styled.svg`
    margin-top: -2px;
    margin-bottom: -2px;
  `,
  separator: styled.div`
    display: flex;
    border-style: none;
  `,
  middle: styled.div`
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 16px;
    margin: -2px;
  `,
  line: styled.div`
    height: 7px;
    margin-left: 2px;
    width: 100%;
    border-bottom: 1px solid rgb(216, 216, 216);
  `,
};
