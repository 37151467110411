import * as React from 'react';
import styled, { keyframes } from 'styled-components';

import { Button, ButtonType } from '../DressingRoomTheme';

type IProps = {
  type: ButtonType,
  onClick?: () => Promise<void>,
  className?: string,
  children: React.ReactNode,
};

type IState = {
  waiting: boolean,
};

class ActionButton extends React.Component<IProps, IState> {
  private isUnMounted = false;

  constructor(props: IProps) {
    super(props);
    this.state = {
      waiting: false,
    };
  }

  public componentWillUnmount(): void {
    this.isUnMounted = true;
  }

  public render() {
    const {
      type,
      onClick,
      className,
      children,
    } = this.props;
    const {
      waiting,
    } = this.state;

    return (
      <ButtonStyle
        type={waiting ? ButtonType.Disabled : type}
        className={className}
        onClick={() => {
          if (!waiting && onClick) {
            this.setState({ waiting: true });
            onClick().then(() => {
              if (!this.isUnMounted) {
                this.setState({ waiting: false });
              }
            });
          }
        }}
      >
        { waiting
          ? (
            <OnTopAndCentered>
              <CircleSpinner />
            </OnTopAndCentered>
          )
          : null}
        <Visible visible={!waiting}>
          {children}
        </Visible>
      </ButtonStyle>
    );
  }
}

export default ActionButton;

const Visible = styled.span<{ visible: boolean }>`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const ButtonStyle = styled(Button)`
  position: relative;
`;

const OnTopAndCentered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const angleToCoords = (angleInDegrees: number) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return { x: Math.cos(angleInRadians), y: Math.sin(angleInRadians) };
};

const arcPath = (radius: number, endAngle: number): string => {
  const start = angleToCoords(0);
  const end = angleToCoords(endAngle);
  const arcSweep = endAngle <= 180 ? '0' : '1';

  return [
    'M', end.x * radius, end.y * radius,
    'A', radius, radius, 0, arcSweep, 0, start.x * radius, start.y * radius,
  ].join(' ');
};

const BlockSVG = styled.svg`
  display: block;
`;

const CircleSpinner = () => (
  <BlockSVG
    width={24}
    height={24}
    viewBox="-64 -64 128 128"
  >
    <FullCircle d={arcPath(56, 359.99)} />
    <Rotator>
      <Arc d={arcPath(56, 120)} />
    </Rotator>
  </BlockSVG>
);

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`;

const Rotator = styled.g`
  animation: ${rotation} 1.0s infinite linear;
`;

const FullCircle = styled.path`
  fill: none;
  stroke: rgb(200, 200, 200);
  stroke-width: 16;
`;

const Arc = styled.path`
  fill: none;
  stroke: rgb(60, 64, 76);
  stroke-width: 16;
`;
