import * as dat from 'dat.gui';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import * as THREE from 'three';

import { isGenderMatch } from '../../dressing-room/lib/dressingRoom/cart/ObjectSelector';
import { AlphatauriTheme, IDressingRoomTheme } from '../../dressing-room/lib/dressingRoom/DressingRoomTheme';
import LoaderDisplay from '../../dressing-room/lib/dressingRoom/ProgressLoaderDisplay';
import ModelViewer from '../../dressing-room/lib/dressingRoom/viewer3D/ModelViewer';
import { Gender, IPlatform } from '../../dressing-room/lib/fision-platform-interface';
import { IFisionSDK } from '../../dressing-room/lib/fision-sdk-interface';
import { FisionSDK } from '../../dressing-room/lib/fision_sdk';
import { getCurrentDemoAPIKey } from '../../dressing-room/lib/shared/demo_api_key';
import { loadUrlParameters } from '../../dressing-room/lib/shared/url_parameters';
import { isHttps } from '../../dressing-room/lib/shared/urls';

import { getShopLink, getSize, sizeToVariantId } from './custom_tailoring';
import { Garment, loadAllGarments } from './garments_on_bodies';
import CustomTailoringDressingRoomUI from './head_up_display';
import { weightedNearestNeighbor } from './nearest_body';
import { interpolateMeshVertexPositions } from './retargeting';
import { createViewer } from './viewer';

const CustomTailoringDressingRoom = (props: {
  fisionSDK: IFisionSDK,
  platform: IPlatform,
  modelViewer: ModelViewer,
  datGui: dat.GUI,
  theme: IDressingRoomTheme,
  garments: Garment[],
  shoppingCartUrl: string,
  qrCodePrintUrl: string,
}) => {
  const {
    fisionSDK,
    platform,
    modelViewer,
    datGui,
    theme,
    garments,
    shoppingCartUrl,
    qrCodePrintUrl,
  } = props;

  const [hasUserBody, setHasUserBody] = React.useState(false);
  const [gender, setGender] = React.useState(Gender.Male);
  const [selectedGarment, setSelectedGarment] = React.useState(0);

  const bodyPropsRef = React.useRef<{[prop: string]: number }>({});
  const garment = garments[selectedGarment];
  const { resources } = garment;

  const setDefaultBody = React.useCallback(() => {
    const bodyProps = bodyPropsRef.current;
    const defaultBody = resources.bodies[0];
    bodyProps.bodyHeight = Math.round(defaultBody.height);
    bodyProps.chest = Math.round(defaultBody.chest);
    bodyProps.waist = Math.round(defaultBody.waist);
  }, [resources.bodies]);

  const onChange = React.useCallback(() => {
    const bodyProps = bodyPropsRef.current;
    const time = Date.now();
    const examples = resources.exampleModels;
    const displayed = resources.displayedModel;
    interpolateMeshVertexPositions(
      examples.map((exampleModel, index) => ({
        positions: exampleModel.body,
        weight: bodyProps[index],
      })),
      displayed.body as THREE.Mesh,
    );
    interpolateMeshVertexPositions(
      examples.map((exampleModel, index) => ({
        positions: exampleModel.lowerGarment,
        weight: bodyProps[index],
      })),
      displayed.lowerGarment as THREE.Mesh,
    );
    interpolateMeshVertexPositions(
      examples.map((exampleModel, index) => ({
        positions: exampleModel.upperGarment,
        weight: bodyProps[index],
      })),
      displayed.upperGarment as THREE.Mesh,
    );
    console.info(`Interpolation in ${Date.now() - time} ms.`);
  }, [resources.displayedModel, resources.exampleModels]);

  const onBody = React.useCallback(() => {
    const bodyProps = bodyPropsRef.current;
    const target = {
      height: bodyProps.bodyHeight,
      chest: bodyProps.chest,
      waist: bodyProps.waist,
    };
    const weights = weightedNearestNeighbor(resources.bodies, target);
    weights.forEach((weight, index) => { bodyProps[index] = weight; });
    datGui.updateDisplay();
    onChange();
  }, [datGui, onChange, resources.bodies]);

  React.useEffect(() => {
    const bodyProps = bodyPropsRef.current;
    fisionSDK.addUserStateChangeListener(userState => {
      if (userState && userState.type === 'SignedInComplete') {
        const { measurements } = userState.latestBodySnapshot;
        bodyProps.bodyHeight = measurements.bodyHeight as number;
        bodyProps.chest = measurements.chestCircumference as number;
        bodyProps.waist = measurements.waistCircumference as number;
        bodyProps.sleeveLength = measurements.armLength as number + 0.5 * (measurements.acromionWidth as number) + 1;
        bodyProps.backLength = measurements.napeToBeltlineBackLength as number;
        setGender(userState.latestBodySnapshot.gender);
        setHasUserBody(true);
        onBody();
      } else {
        setHasUserBody(false);
        setDefaultBody();
        onBody();
      }
    }, 'dressing-room');
    return () => fisionSDK.removeUserStateChangeListener('dressing-room');
  }, [fisionSDK, onBody, setDefaultBody]);

  React.useEffect(() => {
    const bodyProps = bodyPropsRef.current;
    // eslint-disable-next-line no-underscore-dangle
    [...datGui.__controllers].forEach(controller => datGui.remove(controller));

    for (let i = 0; i < resources.exampleModels.length; i += 1) {
      bodyProps[i] = 0;
      datGui.add(bodyProps, `${i}`, 0, 1, 0.05).onFinishChange(onChange);
    }

    if (bodyProps.bodyHeight) {
      datGui.add(bodyProps, 'bodyHeight', 150, 210, 1).onFinishChange(onBody);
      datGui.add(bodyProps, 'chest', 60, 160, 1).onFinishChange(onBody);
      datGui.add(bodyProps, 'waist', 60, 160, 1).onFinishChange(onBody);
    }

    onBody();

    modelViewer.setModel(resources.scene);
  }, [datGui, modelViewer, onBody, onChange, resources.exampleModels.length, resources.scene, selectedGarment]);

  const garmentsOfGender = garments.filter(product => isGenderMatch(product.intendedGender, gender));
  const selectedGarmentOfGender = garmentsOfGender.indexOf(garments[selectedGarment]);

  React.useEffect(() => {
    if (selectedGarmentOfGender < 0 && garmentsOfGender.length > 0) {
      setSelectedGarment(garments.indexOf(garmentsOfGender[0]));
    }
  }, [garments, garmentsOfGender, gender, selectedGarmentOfGender]);

  const bodyProps = bodyPropsRef.current;
  const size = getSize(garment.id, bodyProps.chest / 2, bodyProps.sleeveLength, bodyProps.backLength);
  const variantId = resources && size && sizeToVariantId(resources.firstId, size);

  return (
    <ThemeProvider theme={theme}>
      <CustomTailoringDressingRoomUI
        modelViewer={modelViewer}
        hasUserBody={hasUserBody}
        meeplButtonClicked={() => {
          platform.meeplButtonClicked({
            type: 'VirtualDressingRoom',
          });
        }}
        resetClicked={() => {
          platform.signOut();
        }}
        garments={garmentsOfGender}
        selectedGarment={selectedGarmentOfGender}
        selectGarment={(nextGarment: number) => {
          setSelectedGarment(garments.indexOf(garmentsOfGender[nextGarment]));
        }}
        recommendedVariantLink={variantId && getShopLink(
          shoppingCartUrl,
          variantId,
        )}
        qrCodePrintUrl={qrCodePrintUrl}
      />
    </ThemeProvider>
  );
};

document.addEventListener('DOMContentLoaded', async () => {
  ReactDOM.render(
    React.createElement(
      LoaderDisplay,
      {
        isLoading: true,
        failed: false,
        durationInSeconds: 30,
      },
    ),
    document.getElementById('viewerHUD') as HTMLElement,
  );

  const getAlphatauriApiKey = () => {
    switch (window.location.origin) {
      case 'https://demo.fision.cloud': return '7c3ad1a7-386c-4946-91b9-f70ae23307c7';
      case 'https://demo.fision-staging.cloud': return '37cb516b-cb64-44ed-b6a9-bfb3394db4a1';
      default: return 'b6e86641-9081-4a6a-9dac-8177cc28cb01';
    }
  };

  const apiKey = isHttps()
    ? getAlphatauriApiKey()
    : getCurrentDemoAPIKey();
  const fisionSDK = await FisionSDK.initialize({
    apiKey,
    enableQuickSize: true,
  });
  // eslint-disable-next-line no-underscore-dangle
  const platform = await fisionSDK._platform();

  const configuration = loadUrlParameters({
    debug: false,
    garments: 'garments.json',
    shoppingCartHost: 'alphatauri.com',
    qrCodePrintUrl: 'http://192.168.2.50:8000/print',
  });

  const garments = await loadAllGarments(configuration.garments);

  const modelViewer = createViewer(document.getElementById('viewer') as HTMLElement);

  const datGui = new dat.GUI({ autoPlace: false });
  if (configuration.debug) {
    (document.getElementById('datControls') as HTMLElement).appendChild(datGui.domElement);
  }

  ReactDOM.render(
    React.createElement(
      CustomTailoringDressingRoom,
      {
        fisionSDK,
        platform,
        modelViewer,
        datGui,
        theme: AlphatauriTheme,
        garments,
        shoppingCartUrl: `https://${configuration.shoppingCartHost}/cart/add/?productCodes=`,
        qrCodePrintUrl: configuration.qrCodePrintUrl,
      },
    ),
    document.getElementById('viewerHUD') as HTMLElement,
  );
});
