import { parse } from 'papaparse';
import * as THREE from 'three';

import { loadObjWithPreProcessor, loadTexture } from '../../dressing-room/lib/dressingRoom/viewer3D/ModelLoader';
import { IntendedGender } from '../../dressing-room/lib/fision-platform-interface';

import { getVertices } from './retargeting';
import { loadObject3D, prepareTexturingFunc } from './viewer';

const readCSV = async (url: string) => {
  const csv = await fetch(url).then(response => response.text());
  const result = parse(csv, {
    header: true,
    skipEmptyLines: true,
  });
  if (result.errors.length > 0) {
    throw new Error(JSON.stringify(result.errors, null, 2));
  }
  return result.data;
};

const filterFaces = (objFileContent: string, faceIndices: Set<number> | undefined) => {
  if (!faceIndices) {
    return objFileContent;
  }
  let faceIndexOffset = -1;
  return objFileContent
    .split('\n')
    .filter((line, index) => {
      if (line.startsWith('f ')) {
        if (faceIndexOffset === -1) {
          faceIndexOffset = index;
        }
        const faceIndex = index - faceIndexOffset;
        return faceIndices.has(faceIndex);
      }
      return true;
    })
    .join('\n');
};

type Body = {
  id: string,
  height: number,
  chest: number,
  waist: number,
};
export type GarmentResources = {
  displayedModel: {
    body: THREE.Object3D,
    lowerGarment: THREE.Object3D,
    upperGarment: THREE.Object3D,
  },
  exampleModels: Array<{
    body: THREE.Vector3[],
    lowerGarment: THREE.Vector3[],
    upperGarment: THREE.Vector3[],
  }>,
  bodies: Array<Body>,
  scene: THREE.Group,
  thumbnailUrl: string,
  firstId: string,
};

export const loadGarment = async (garment: Garment): Promise<GarmentResources[]> => {
  const url = garment.resourcesUrl;

  const bodies: Array<{
    id: string,
    height: number,
    chest: number,
    waist: number,
  }> = await readCSV(`${url}/body_measurements.csv`) as any;

  let bodyFaces: Set<number> | undefined;
  try {
    bodyFaces = new Set<number>(
      await fetch(`${url}/body_faces.json`)
        .then(response => response.json()),
    );
  } catch {
    // use all body faces
  }

  const computeNormals = (object3D: THREE.Object3D) => {
    (object3D.children[0] as THREE.Mesh).geometry.computeVertexNormals();
    return object3D;
  };

  const loadModel = async (bodyId: string) => {
    const dir = `${url}/${bodyId}/`;
    return {
      body: computeNormals(
        await loadObjWithPreProcessor(`${dir}body.obj`, content => filterFaces(content, bodyFaces)),
      ),
      lowerGarment: garment.hasLowerGarment
        ? computeNormals(await loadObject3D(`${dir}lowerGarment.obj`))
        : new THREE.Group(),

      upperGarment: computeNormals(
        await loadObject3D(`${dir}upperGarment.obj`),
      ),
    };
  };

  const displayedModel = await loadModel(bodies[0].id);
  const offset = new THREE.Vector3(0, 1.25, 0);
  const prepareTexturing = prepareTexturingFunc();

  const exampleModels: Array<{
    body: THREE.Vector3[],
    lowerGarment: THREE.Vector3[],
    upperGarment: THREE.Vector3[],
  }> = [];

  async function loadModelsForBody(bodyId: string) {
    const exampleModel = await loadModel(bodyId);
    exampleModels.push({
      body: getVertices(exampleModel.body),
      lowerGarment: getVertices(exampleModel.lowerGarment),
      upperGarment: getVertices(exampleModel.upperGarment),
    });
    if (exampleModels.length >= 2) {
      const e1 = exampleModels[0];
      const e2 = exampleModels[exampleModels.length - 1];
      if (e1.body.length !== e2.body.length
        || e1.lowerGarment.length !== e2.lowerGarment.length
        || e1.upperGarment.length !== e2.upperGarment.length) {
        console.error(`One or more meshes of ${url}/${bodyId} differ in the number of vertices`, e1, e2);
      }
    }
  }
  await Promise.all(bodies.map(body => loadModelsForBody(body.id)));

  const textures = await Promise.all(garment.variants.map(
    variant => loadTexture(`${url}/variants/${variant.color}/upperGarment.jpg`),
  ));

  const clone = (object3D: THREE.Object3D) => {
    const group = new THREE.Group();
    const firstChild = object3D.children[0];
    if (firstChild) {
      group.add(firstChild.clone());
    }
    return group;
  };

  return garment.variants.map((variant, index) => {
    const thumbnailUrl = `${url}/variants/${variant.color}/thumbnail.png`;
    const upperGarmentTexture = textures[index];

    const displayedBody = prepareTexturing(clone(displayedModel.body), offset);
    const displayedLowerGarment = prepareTexturing(clone(displayedModel.lowerGarment), offset);
    const displayedUpperGarment = prepareTexturing(clone(displayedModel.upperGarment), offset, upperGarmentTexture);

    const model = new THREE.Group();
    model.add(displayedBody);
    model.add(displayedLowerGarment);
    model.add(displayedUpperGarment);
    const scene = new THREE.Group();
    scene.add(model);

    return {
      displayedModel: {
        body: displayedBody,
        lowerGarment: displayedLowerGarment,
        upperGarment: displayedUpperGarment,
      },
      exampleModels,
      bodies,
      scene,
      thumbnailUrl,
      firstId: variant.firstId,
    };
  });
};

export type GarmentVariant = {
  color: string,
  firstId: string,
  resourcesUrl: string,
  croppedResourcesUrl: string,
};

export type Garment = {
  id: string,
  name: string,
  headline: string,
  summary?: string,
  price?: string,
  originalPrice?: string,
  intendedGender: IntendedGender,
  resourcesUrl: string,
  hasLowerGarment: boolean,
  resources: GarmentResources,
  variants: GarmentVariant[],
};

export const loadAllGarments = async (garmentsMetaUrl: string) => {
  const garments: Garment[] = await fetch(garmentsMetaUrl).then(response => response.json());
  if (!garments || garments.length < 1) {
    throw new Error('No garment meta');
  }
  const garmentsExpanded: Garment[] = [];
  (await Promise.all(garments.map(meta => loadGarment(meta))))
    .forEach((resources, index) => {
      resources.forEach(resource => {
        garmentsExpanded.push({
          ...garments[index],
          resources: resource,
        });
      });
    });

  return garmentsExpanded;
};
