const ENV: {[key: string]: string | undefined} = {
  KUBERNETES_NAMESPACE: process.env.KUBERNETES_NAMESPACE,
  GCP_PROJECT_DOMAIN: process.env.GCP_PROJECT_DOMAIN,
};

export const isHttps = () => window.location.protocol === 'https:';

export const getGraphQLURL = () => `${getAPIURL()}/graphql`;

export const getLegalDocsURL = () => `https://legal-documents.${getValidatedEnvVariable('GCP_PROJECT_DOMAIN')}`;

export const getAcceptTermsURL = () => `${getAPIURL()}/accounts/user/current-user-id/accept-terms`;

export const getCreateCustomTokenURL = () => `${getAPIURL()}/accounts/user/current-user-id/create-custom-token`;

const getAPIURL = () => {
  const namespace = getValidatedEnvVariable('KUBERNETES_NAMESPACE');
  const namespaceSuffix = namespace === 'default' ? '' : `/${namespace}`;
  return `https://api.${getValidatedEnvVariable('GCP_PROJECT_DOMAIN')}${namespaceSuffix}`;
};

const getValidatedEnvVariable = (variableName: string): string => {
  const variable = ENV[variableName];
  if (variable == null) {
    throw new Error(`Environment variable ${variableName} not specified in .env file.`);
  }

  return variable;
};
