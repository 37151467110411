import Stats from 'stats.js';
import * as THREE from 'three';

import { loadGLB, loadObj } from '../../dressing-room/lib/dressingRoom/viewer3D/ModelLoader';
import ModelViewer, { RenderQuality } from '../../dressing-room/lib/dressingRoom/viewer3D/ModelViewer';
import { skyConfigurations } from '../../dressing-room/lib/dressingRoom/viewer3D/SkyDome';
import { loadUrlParameters } from '../../dressing-room/lib/shared/url_parameters';

export const loadObject3D = async (
  url: string,
): Promise<THREE.Object3D> => {
  const loader = url.includes('.obj') ? loadObj : loadGLB;
  return loader(url);
};

export const prepareTexturingFunc = () => {
  const allMaterials: THREE.MeshStandardMaterial[] = [];

  const material = new THREE.MeshStandardMaterial({
    color: new THREE.Color('rgb(200, 200, 200)'),
    metalness: 0.0,
    roughness: 0.5,
  });

  const materialWithTexture = (map: THREE.Texture) => {
    const mat = new THREE.MeshStandardMaterial({
      map,
      metalness: 0.0,
      roughness: 1.0,
      wireframe: false,
    });
    allMaterials.push(mat);
    return mat;
  };

  window.addEventListener('keydown', event => {
    if (event.key === 'w') {
      allMaterials.forEach(mat => {
        mat.setValues({ wireframe: !mat.wireframe });
      });
    }
  });

  return (
    object: THREE.Object3D,
    translation?: THREE.Vector3,
    texture?: THREE.Texture,
  ) => {
    const insideMeshes: Array<{parent: THREE.Object3D, mesh: THREE.Mesh}> = [];
    object.traverse(node => {
      if (node.type !== 'Mesh') {
        return;
      }
      const mesh = node as THREE.Mesh;
      const meshMaterial = mesh.material as THREE.MeshStandardMaterial;
      const tex = meshMaterial.map || texture;
      if (tex) {
        mesh.material = materialWithTexture(tex);

        const insideMesh = mesh.clone();
        insideMesh.material = materialWithTexture(tex);
        insideMesh.material.side = THREE.BackSide;
        insideMesh.receiveShadow = true;
        insideMesh.castShadow = false;
        if (mesh.parent) {
          insideMeshes.push({ parent: mesh.parent, mesh: insideMesh });
        }
      } else {
        mesh.material = material;
      }

      mesh.receiveShadow = true;
      mesh.castShadow = true;
    });
    insideMeshes.forEach(entry => entry.parent.add(entry.mesh));

    if (translation) {
      object.translateX(translation.x);
      object.translateY(translation.y);
      object.translateZ(translation.z);
    }

    return object;
  };
};

export const createViewer = (
  viewerElement: Element,
) => {
  const configuration = loadUrlParameters({
    frameRate: false,
    quality: 'high',
    sky: 'studio',
    alwaysAnimate: true,
    rotationSpeed: 0.15,
  });

  let update = null;
  if (configuration.frameRate) {
    const stats = new Stats();
    stats.showPanel(0);
    stats.dom.style.cssText = 'position: absolute; top: 0px; left: 50%;';
    viewerElement.appendChild(stats.dom);
    update = () => stats.update();
  }

  return new ModelViewer(
    viewerElement,
    update,
    RenderQuality[configuration.quality as 'high' | 'low' | 'auto'] || RenderQuality.high,
    configuration.alwaysAnimate,
    skyConfigurations[configuration.sky] || skyConfigurations.studio,
    undefined,
    undefined,
    configuration.rotationSpeed,
    undefined,
    undefined,
    undefined,
    {
      x: 0.4,
      y: 0.666,
    },
  );
};
