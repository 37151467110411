import * as React from 'react';
import styled from 'styled-components';

const RemoveButton = (props: { onClick: () => void }) => (
  <svg
    width={16}
    height={16}
  >
    <s.button transform="translate(8, 8)" onClick={props.onClick} onTouchEnd={props.onClick}>
      <s.circle r={8} />
      <s.line x1={-4} y1={4} x2={4} y2={-4} />
      <s.line x1={-4} y1={-4} x2={4} y2={4} />
    </s.button>
  </svg>
);

export default RemoveButton;

const s = {
  button: styled.g`
    cursor: pointer;
  `,

  circle: styled.circle`
    fill: rgb(60, 64, 76);
    stroke: none;
  `,

  line: styled.line`
    stroke: rgb(255, 255, 255);
    stroke-width: 2;
  `,
};
