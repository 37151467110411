const randomWait = (maxTimeInMs: number) => new Promise(resolve => setTimeout(resolve, Math.random() * maxTimeInMs));

export const truncatedExponentialBackoff = async <R>(
  supplier: () => Promise<R>,
  maxRepeats = 4,
  maxBackOffTimeInMs = 1000,
): Promise<R> => {
  try {
    return await supplier();
  } catch (e) {
    if (maxRepeats < 1) {
      throw e;
    }

    await randomWait(maxBackOffTimeInMs);

    return truncatedExponentialBackoff(
      supplier,
      maxRepeats - 1,
      maxBackOffTimeInMs * 2,
    );
  }
};

export const fetchThrowingServerErrors = async (
  input: RequestInfo,
  init?: RequestInit | undefined,
): Promise<Response> => {
  const response = await fetch(input, init);
  if (response.status === 429 || response.status >= 500) {
    throw new Error(`Server responded with status code ${response.status} for ${input}`);
  }
  return response;
};

export const fetchRepeatingOnServerErrors = async (
  input: RequestInfo,
  init?: RequestInit | undefined,
): Promise<Response> => truncatedExponentialBackoff(() => fetchThrowingServerErrors(input, init));
