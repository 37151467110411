// @ts-ignore
import TWEEN from '@tweenjs/tween.js';
import * as THREE from 'three';

import { isAndroidOrIOS } from '../../shared/browser_detection';

export type ISpherical = {
  copy: () => ISpherical,
  add: (rotationAroundY: number, inclination: number) => ISpherical,
  toEuclidean: () => THREE.Vector3,
  interpolate: (target: ISpherical, alpha: number) => ISpherical,
  radius: () => number,
  rotationAroundY: () => number,
  inclination: () => number,
  equals: (other: ISpherical, epsilon?: number) => boolean,
};

const normalizeRadianBetweenMinusPiAndPi = (radian: number) => (
  radian + Math.ceil((-radian - Math.PI) / (2 * Math.PI)) * 2 * Math.PI
);

/*
  Spherical coordinates
  Sphere with specified radius, center is (x,y,z)=(0,0,0). Right-handed coordinate system (y up).
  rotationAroundY [-PI..+PI]. z=1 => rotationAroundY=0, x=1 => rotationAroundY=PI/2
  inclination: [-PI/2..+PI/2]
*/
export const Spherical = (
  radius: number,
  rotationAroundY: number,
  inclination: number,
  inclinationLimit = {
    lower: -Math.PI / 2,
    upper: Math.PI / 2,
  },
): ISpherical => ({
  copy: () => Spherical(radius, rotationAroundY, inclination, inclinationLimit),
  add: (deltaRotationAroundY: number, deltaInclination: number) => Spherical(
    radius,
    normalizeRadianBetweenMinusPiAndPi(rotationAroundY + deltaRotationAroundY),
    Math.max(
      inclinationLimit.lower,
      Math.min(
        inclinationLimit.upper,
        inclination + deltaInclination,
      ),
    ),
    inclinationLimit,
  ),
  toEuclidean: () => new THREE.Vector3(
    radius * Math.sin(rotationAroundY) * Math.cos(inclination),
    radius * Math.sin(inclination),
    radius * Math.cos(rotationAroundY) * Math.cos(inclination),
  ),
  interpolate: (target: ISpherical, alpha: number) => {
    let from = normalizeRadianBetweenMinusPiAndPi(rotationAroundY);
    let to = normalizeRadianBetweenMinusPiAndPi(target.rotationAroundY());
    if (Math.abs(to - from) > Math.PI) {
      from += from < to ? 2 * Math.PI : 0;
      to += to < from ? 2 * Math.PI : 0;
    }

    return Spherical(
      radius,
      normalizeRadianBetweenMinusPiAndPi(from * (1 - alpha) + to * alpha),
      inclination * (1 - alpha) + target.inclination() * alpha,
      inclinationLimit,
    );
  },
  radius: () => radius,
  rotationAroundY: () => rotationAroundY,
  inclination: () => inclination,
  equals: (other: ISpherical, epsilon = 0.0001) => {
    if (!other) {
      return false;
    }
    const closeEnough = (a: number, b: number) => Math.abs(a - b) < epsilon;

    const otherRotation = other.rotationAroundY();

    return closeEnough(radius, other.radius())
        && (closeEnough(rotationAroundY, otherRotation)
          || closeEnough(
            Math.min(rotationAroundY, otherRotation) + 2 * Math.PI,
            Math.max(rotationAroundY, otherRotation),
          ))
        && closeEnough(inclination, other.inclination());
  },
});

export const sphericalFromPosition = (
  position: THREE.Vector3,
  sphereCenter?: THREE.Vector3,
) => {
  const p = position.clone();
  if (sphereCenter) {
    p.sub(sphereCenter);
  }
  const radius = p.length();
  const rotationAroundY = Math.atan2(p.x, p.z);
  const inclination = Math.asin(p.y / radius);
  return Spherical(
    radius,
    rotationAroundY,
    inclination,
    {
      lower: -Math.PI / 8.0,
      upper: (Math.PI / 2.0) * 0.9,
    },
  );
};

type Coordinates = {
  x: number,
  y: number,
};

enum MouseButton {
  Left = 0,
  Right = 2,
}

const Dragger = (
  domElement: HTMLElement,
  newDragger: (button: MouseButton) => (delta: Coordinates) => void,
) => {
  const state: {
    dragStart: Coordinates,
    onDrag: (delta: Coordinates) => void,
  } = {
    dragStart: {
      x: 0,
      y: 0,
    },
    onDrag: () => undefined,
  };

  const onMouseMove = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    state.onDrag({
      x: event.screenX - state.dragStart.x,
      y: event.screenY - state.dragStart.y,
    });
  };

  const onMouseUp = (event: MouseEvent) => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
    event.stopPropagation();
    event.preventDefault();
  };

  domElement.addEventListener('contextmenu', event => {
    event.preventDefault();
  });

  domElement.addEventListener('mousedown', event => {
    if (event.button !== 0 && event.button !== 2) {
      return;
    }

    state.dragStart = {
      x: event.screenX,
      y: event.screenY,
    };
    state.onDrag = newDragger(event.button);

    event.stopPropagation();
    event.preventDefault();
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  });
};

const pinchSize = (event: TouchEvent) => {
  const dx = event.touches[0].pageX - event.touches[1].pageX;
  const dy = event.touches[0].pageY - event.touches[1].pageY;
  return Math.sqrt(dx * dx + dy * dy);
};

const getTouchPosition = (touch: Touch) => {
  const element = touch.target as HTMLElement;
  const offsetX = element.offsetLeft || 0;
  const offsetY = element.offsetTop || 0;
  return {
    x: touch.clientX - offsetX,
    y: touch.clientY - offsetY,
  };
};

const mean = (
  coord1: Coordinates,
  coord2: Coordinates,
) => ({
  x: 0.5 * (coord1.x + coord2.x),
  y: 0.5 * (coord1.y + coord2.y),
});

const pinchCenter = (event: TouchEvent) => mean(
  getTouchPosition(event.touches[0]),
  getTouchPosition(event.touches[1]),
);

const Toucher = (
  domElement: HTMLElement,
  newPincher: (center: Coordinates) => (ratio: number, delta: Coordinates) => void,
  newDragger: (start: Coordinates) => ((delta: Coordinates) => void) | null,
) => {
  let consumer: ((moveEvent: TouchEvent) => void) | null = null;

  const TouchDragger = (event: TouchEvent) => {
    const rect = (event.target as HTMLCanvasElement).getBoundingClientRect();
    const dragStart = {
      x: event.targetTouches[0].pageX - rect.left,
      y: event.targetTouches[0].pageY - rect.top,
    };
    const onDrag = newDragger(dragStart);
    return onDrag && ((moveEvent: TouchEvent) => {
      if (event.touches.length !== 1) {
        return;
      }
      onDrag({
        x: moveEvent.targetTouches[0].pageX - rect.left - dragStart.x,
        y: moveEvent.targetTouches[0].pageY - rect.top - dragStart.y,
      });
    });
  };

  const TouchPincher = (event: TouchEvent) => {
    const startDistance = pinchSize(event);
    const center = pinchCenter(event);
    const onPinch = newPincher(center);
    return (moveEvent: TouchEvent) => {
      if (moveEvent.touches.length !== 2) {
        return;
      }
      const distance = pinchSize(moveEvent);
      const ratio = startDistance / distance;
      const newCenter = pinchCenter(moveEvent);
      onPinch(ratio, {
        x: newCenter.x - center.x,
        y: newCenter.y - center.y,
      });
    };
  };

  domElement.addEventListener('touchstart', event => {
    if (event.touches.length === 1) {
      consumer = TouchDragger(event);
    } else if (event.touches.length === 2) {
      consumer = TouchPincher(event);
    }
  });

  domElement.addEventListener('touchmove', event => {
    if (consumer) {
      consumer(event);
    }

    if (event.cancelable) {
      event.preventDefault();
      event.stopPropagation();
    }
  });
};

const DoubleTapper = (
  domElement: HTMLElement,
  onDoubleTap: (position: Coordinates) => void,
) => {
  let lastTap: {
    time: number,
    position: Coordinates,
  };

  const distance = (position1: Coordinates, position2: Coordinates) => {
    const dx = position1.x - position2.x;
    const dy = position1.y - position2.y;
    return Math.sqrt(dx * dx + dy * dy);
  };

  domElement.addEventListener('touchend', event => {
    if (event.changedTouches.length !== 1) {
      return;
    }

    const tap = {
      time: Date.now(),
      position: getTouchPosition(event.changedTouches[0]),
    };

    if (lastTap && tap.time - lastTap.time < 500 && distance(tap.position, lastTap.position) < 25) {
      onDoubleTap(tap.position);
    }
    lastTap = tap;
  });
};

export type OrbitControls = {
  updateTime: (time: number) => void,
  updateView: () => void,
  zoomIn: () => void,
  zoomOut: () => void,
  zoomReset: () => void,
};

export const OrbitControls = (
  parentDomElement: HTMLElement,
  camera: THREE.PerspectiveCamera,
  initialPosition: THREE.Vector3,
  lookAt: THREE.Vector3,
  onUpdate?: () => void,
  cropLimits: [number, number] = [0.1, 2],
  fixAxisX = false,
  onZoom?: (isZoomedIn: boolean) => void,
  getModel?: () => THREE.Object3D | null,
  initialCropCenterNorm = {
    x: 0.5,
    y: 0.666,
  },
): OrbitControls => {
  const initialState = {
    sphericalPosition: sphericalFromPosition(initialPosition, lookAt),
    cropCenterNorm: initialCropCenterNorm,
    cropFactor: 1,
    time: 0,
  };

  const state = {
    ...initialState,
    cropCenterNorm: { ...initialState.cropCenterNorm },
  };

  const updateCamera = () => {
    const p = state.sphericalPosition.toEuclidean();
    p.add(lookAt);
    camera.position.set(p.x, p.y, p.z);
    camera.lookAt(lookAt);
    camera.updateProjectionMatrix();
  };
  updateCamera();

  const computeView = () => {
    const w = parentDomElement.clientWidth;
    const h = parentDomElement.clientHeight;

    return {
      fullWidth: w,
      fullHeight: h,
      offsetX: w * (state.cropCenterNorm.x - 0.5 * state.cropFactor),
      offsetY: h * (state.cropCenterNorm.y - 0.5 * state.cropFactor),
      width: w * state.cropFactor,
      height: h * state.cropFactor,
    };
  };

  const updateView = () => {
    const view = computeView();
    camera.setViewOffset(
      view.fullWidth,
      view.fullHeight,
      view.offsetX,
      view.offsetY,
      view.width,
      view.height,
    );
  };
  updateView();

  const zoomThreshold = 0.25;
  const setCropFactor = (newCropFactor: number) => {
    if (onZoom
      && Math.min(newCropFactor, state.cropFactor) <= zoomThreshold
      && Math.max(newCropFactor, state.cropFactor) > zoomThreshold) {
      onZoom(newCropFactor <= zoomThreshold);
    }
    state.cropFactor = newCropFactor;
  };

  const getCoordsNorm = (coords: Coordinates) => {
    const view = computeView();
    return {
      x: (view.offsetX + (coords.x / view.fullWidth) * view.width) / view.fullWidth,
      y: (view.offsetY + (coords.y / view.fullHeight) * view.height) / view.fullHeight,
    };
  };

  const limitCropCenter = (cropCenterNorm: Coordinates) => {
    const limit = (value: number) => Math.max(0, Math.min(1, value));
    return {
      x: limit(cropCenterNorm.x),
      y: limit(cropCenterNorm.y),
    };
  };

  const limitCrop = (factor: number) => Math.max(cropLimits[0], Math.min(cropLimits[1], factor));

  const isOnModel = (coords: Coordinates) => {
    if (getModel == null) {
      return true;
    }
    const model = getModel();
    if (model == null) {
      return false;
    }

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera({
      x: (coords.x / parentDomElement.clientWidth) * 2 - 1,
      y: (-coords.y / parentDomElement.clientHeight) * 2 + 1,
    }, camera);

    return raycaster.intersectObject(model, true).length > 0;
  };

  const Rotator = () => {
    const startPosition = state.sphericalPosition;
    return (delta: Coordinates) => {
      state.sphericalPosition = startPosition.add(-delta.x / 100, delta.y / 100);
      updateCamera();

      if (onUpdate) {
        onUpdate();
      }
    };
  };

  Dragger(
    parentDomElement,
    button => {
      if (button === MouseButton.Right) {
        const startCropCenterNorm = { ...state.cropCenterNorm };

        return delta => {
          const w = parentDomElement.clientWidth;
          const h = parentDomElement.clientHeight;

          if (!fixAxisX) {
            state.cropCenterNorm = limitCropCenter({
              x: startCropCenterNorm.x - (delta.x / w) * state.cropFactor,
              y: startCropCenterNorm.y - (delta.y / h) * state.cropFactor,
            });
          } else {
            // Only allow dragging on the y-axis.
            state.cropCenterNorm = limitCropCenter({
              x: startCropCenterNorm.x,
              y: startCropCenterNorm.y - (delta.y / h) * state.cropFactor,
            });
          }

          updateView();
        };
      }

      return Rotator();
    },
  );

  Toucher(
    parentDomElement,
    (center: Coordinates) => {
      const stickyPointNorm = getCoordsNorm(center);
      const startState = {
        cropFactor: state.cropFactor,
        cropCenterNorm: { ...state.cropCenterNorm },
      };
      return (ratio, delta) => {
        const w = parentDomElement.clientWidth;
        const h = parentDomElement.clientHeight;

        const newCropFactor = limitCrop(startState.cropFactor * ratio);

        const stickyZoom = {
          x: (stickyPointNorm.x - startState.cropCenterNorm.x) * (1 - newCropFactor / startState.cropFactor),
          y: (stickyPointNorm.y - startState.cropCenterNorm.y) * (1 - newCropFactor / startState.cropFactor),
        };

        const drag = {
          x: (-delta.x / w) * newCropFactor,
          y: (-delta.y / h) * newCropFactor,
        };

        state.cropCenterNorm = limitCropCenter({
          x: startState.cropCenterNorm.x + stickyZoom.x + drag.x,
          y: startState.cropCenterNorm.y + stickyZoom.y + drag.y,
        });
        setCropFactor(newCropFactor);

        updateView();
      };
    },
    start => (isOnModel(start) ? Rotator() : null),
  );

  const zoomTo = (newCropFactor: number, stickyPointNorm: Coordinates) => {
    const stickyZoom = {
      x: (stickyPointNorm.x - state.cropCenterNorm.x) * (1 - newCropFactor / state.cropFactor),
      y: (stickyPointNorm.y - state.cropCenterNorm.y) * (1 - newCropFactor / state.cropFactor),
    };

    return limitCropCenter({
      x: state.cropCenterNorm.x + stickyZoom.x,
      y: state.cropCenterNorm.y + stickyZoom.y,
    });
  };

  parentDomElement.addEventListener('wheel', event => {
    const coords = {
      x: event.offsetX,
      y: event.offsetY,
    };
    if (!isOnModel(coords)) {
      return;
    }

    const stickyPointNorm = getCoordsNorm(coords);

    if (fixAxisX) {
      stickyPointNorm.x = 0.5;
    }

    const newCropFactor = limitCrop(state.cropFactor * (event.deltaY > 0 ? 1.2 : 0.8));

    state.cropCenterNorm = zoomTo(newCropFactor, stickyPointNorm);
    setCropFactor(newCropFactor);

    updateView();

    event.preventDefault();
    event.stopPropagation();
  });

  const resetRotation = (duration: number) => {
    const start = state.sphericalPosition;

    const alpha = { value: 0 };
    new TWEEN.Tween(alpha)
      .to({ value: 1 }, duration)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(() => {
        state.sphericalPosition = start.interpolate(initialState.sphericalPosition, alpha.value);
        updateCamera();
      })
      .start(state.time);
  };

  const resetZoom = (
    duration: number,
    targetState: {
      cropCenterNorm: Coordinates,
      cropFactor: number,
    },
  ) => {
    const cropFactor = {
      value: state.cropFactor,
    };
    new TWEEN.Tween(cropFactor)
      .to({ value: targetState.cropFactor }, duration)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(() => {
        setCropFactor(cropFactor.value);
        updateView();
      })
      .start(state.time);

    new TWEEN.Tween(state.cropCenterNorm)
      .to({
        x: targetState.cropCenterNorm.x,
        y: targetState.cropCenterNorm.y,
      }, duration)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onUpdate(() => {
        updateView();
      })
      .start(state.time);
  };

  const onDoubleClick = (position: Coordinates) => {
    if (state.cropFactor !== 1) {
      resetZoom(0.3, initialState);
      return;
    }

    const stickyPointNorm = getCoordsNorm(position);

    const cropFactor = 0.25;
    resetZoom(0.3, {
      cropCenterNorm: zoomTo(cropFactor, stickyPointNorm),
      cropFactor,
    });
  };

  if (isAndroidOrIOS()) {
    DoubleTapper(
      parentDomElement,
      onDoubleClick,
    );
  } else {
    parentDomElement.addEventListener('dblclick', event => {
      onDoubleClick({
        x: event.offsetX,
        y: event.offsetY,
      });

      event.preventDefault();
      event.stopPropagation();
    });
  }

  const crop = (factor: number) => {
    setCropFactor(limitCrop(factor));
    updateView();
  };

  return {
    updateTime: (time: number) => {
      state.time = time;
    },
    updateView,
    zoomIn: () => {
      if (state.cropFactor >= 1) {
        state.cropCenterNorm = { x: 0.5, y: 0.5 };
      }
      crop(state.cropFactor * 0.5);
    },
    zoomOut: () => {
      crop(state.cropFactor * 2);
    },
    zoomReset: () => {
      resetRotation(1.0);
      resetZoom(1.0, initialState);
    },
  };
};
