export type ILinearRegression = {
  slope: number,
  intercept: number,
  r2: number,
};

export type IDataPoint = {
  x: number,
  y: number,
};

const linearRegression = (observations: IDataPoint[]): ILinearRegression => {
  const sum = {
    x: 0,
    y: 0,
    xy: 0,
    xx: 0,
    yy: 0,
  };

  observations.forEach(dataPoint => {
    sum.x += dataPoint.x;
    sum.y += dataPoint.y;
    sum.xy += dataPoint.x * dataPoint.y;
    sum.xx += dataPoint.x * dataPoint.x;
    sum.yy += dataPoint.y * dataPoint.y;
  });

  const n = observations.length;
  const slopeY = n * sum.xy - sum.x * sum.y;
  const slopeX = n * sum.xx - sum.x * sum.x;
  const slope = slopeY / slopeX;

  return {
    slope,
    intercept: (sum.y - slope * sum.x) / n,
    r2: (slopeY / Math.sqrt(slopeX * (n * sum.yy - sum.y * sum.y))) ** 2,
  };
};

export default linearRegression;
