import * as React from 'react';
import styled from 'styled-components';

import { Button, GeneralStyle } from '../../dressing-room/lib/dressingRoom/DressingRoomTheme';
import ModelViewer from '../../dressing-room/lib/dressingRoom/viewer3D/ModelViewer';
import ZoomControls from '../../dressing-room/lib/dressingRoom/viewer3D/ZoomControls';

import {
  BodyScanCompletedDialog, IntroDialog, PrintedDialog, SignOutDialog,
} from './dialogs';
import { Garment } from './garments_on_bodies';
import ProductInfo from './product_info';
import ProductSelector from './product_selector';

const CustomTailoringDressingRoomUI = (props: {
  modelViewer: ModelViewer,
  hasUserBody: boolean,
  meeplButtonClicked: () => void,
  resetClicked: () => void,
  garments: Garment[],
  selectedGarment: number,
  selectGarment: (garment: number) => void,
  recommendedVariantLink: string | null,
  qrCodePrintUrl: string,
}) => {
  const {
    modelViewer,
    hasUserBody,
    meeplButtonClicked,
    resetClicked,
    garments,
    selectedGarment,
    selectGarment,
    recommendedVariantLink,
    qrCodePrintUrl,
  } = props;

  const [hasResetDialog, setHasResetDialog] = React.useState(false);
  const [hasPrintedDialog, setHasPrintedDialog] = React.useState(false);
  const [hasBodyScanCompletedDialog, sethasBodyScanCompletedDialog] = React.useState(false);

  React.useEffect(() => {
    if (hasUserBody) {
      sethasBodyScanCompletedDialog(true);
    }
  }, [hasUserBody]);

  return (
    <GeneralStyle>
      <s.headUpDisplay>
        <s.productInfoContainer>
          <ProductInfo
            product={garments[selectedGarment]}
            recommendedVariantLink={hasUserBody ? recommendedVariantLink : null}
            qrCodePrintUrl={qrCodePrintUrl}
            onPrint={() => setHasPrintedDialog(true)}
          />
          {hasUserBody
          && (
          <s.resetButtton
            onClick={() => setHasResetDialog(true)}
          >
            Remove avatar from fitting room
          </s.resetButtton>
          )}
        </s.productInfoContainer>
        <s.zoomContainer>
          <ZoomControls
            onZoomOut={modelViewer.zoomOut}
            onZoomIn={modelViewer.zoomIn}
            onZoomReset={modelViewer.zoomReset}
          />
        </s.zoomContainer>
        <s.cartContainer>
          <ProductSelector
            products={garments}
            selectedProduct={selectedGarment}
            selectProduct={selectGarment}
          />
        </s.cartContainer>
      </s.headUpDisplay>
      {!hasUserBody && (
      <IntroDialog
        meeplButtonClicked={meeplButtonClicked}
      />
      )}
      {hasUserBody && hasBodyScanCompletedDialog && (
      <BodyScanCompletedDialog
        onClose={() => {
          sethasBodyScanCompletedDialog(false);
        }}
      />
      )}
      {hasUserBody && hasResetDialog && (
      <SignOutDialog
        signOut={(doSignOut: boolean) => {
          setHasResetDialog(false);
          if (doSignOut) {
            resetClicked();
          }
        }}
      />
      )}
      {hasUserBody && hasPrintedDialog && (
      <PrintedDialog
        signOut={(doSignOut: boolean) => {
          setHasPrintedDialog(false);
          if (doSignOut) {
            resetClicked();
          }
        }}
      />
      )}
    </GeneralStyle>
  );
};

export default CustomTailoringDressingRoomUI;

const s = {
  headUpDisplay: styled.div`
    position: absolute;
    top: 80px;
    bottom: 80px;
    width: calc(100% - 160px);
    left: 50%;
    transform: translate(-50%, 0%);
    pointer-events: none;
  `,
  productInfoContainer: styled.div`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: auto;
  `,
  cartContainer: styled.div`
    position: absolute;
    transition: opacity 0.3s;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: auto;
  `,
  bodyContainer: styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    pointer-events: auto;
    text-align: right;
  `,
  profile: styled.div`
    display: flex;
  `,
  buttonWithIcon: styled(Button)`
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 8px;
    align-items: center;
  `,
  resetButtton: styled.div`
    margin-top: 32px;
    text-decoration-line: underline;
    cursor: pointer;
    font-size: 14px;
  `,
  zoomContainer: styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    pointer-events: auto;
  `,
};
