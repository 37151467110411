export function loadUrlParameters<T extends { [key: string]: any }>(defaultParams: T, useParentFrame = false): T {
  const data = { ...defaultParams } as any;

  const setParam = (value: string, key: string) => {
    if (data[key] !== undefined) {
      try {
        data[key] = JSON.parse(value);
      } catch (e) {
        data[key] = value;
      }
    }
  };

  new URL(window.location.href).searchParams.forEach(setParam);

  if (useParentFrame) {
    new URL(document.referrer).searchParams.forEach(setParam);
  }

  return data;
}
