/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IDressingRoomConfig } from './fision-dressing-room-interface';
import {
  BodyShape,
  Gender,
  IBodyMeasurements,
  IPlatform,
  IPlatformConfig,
  IRecommendationFeedback,
  UserIntentState,
  UserStateExternal,
} from './fision-platform-interface';
import {
  IButtonOptions,
  IDressingRoomSDK,
  IFisionSDK,
  ISizeRecommendationButtonOptions,
} from './fision-sdk-interface';

export const FisionSDK = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialize: async (options: IPlatformConfig): Promise<IFisionSDK> => {
    const platform = createPlatform();
    const bodyProfile = {
      id: '',
      processType: '',
      gender: Gender.Female,
      measurements: {
        bodyHeight: 170,
        chestCircumference: 90,
        waistCircumference: 65,
        armLength: 60,
        napeToBeltlineBackLength: 60,
      } as IBodyMeasurements,
      bodyRepresentationURL: '',
      avatarRepresentationURL: '',
      userId: '',
    };

    return {
      _platform: async () => platform,
      addUserStateChangeListener: (consumer: (userState: UserStateExternal) => void, key?: string) => {},
      removeUserStateChangeListener: (key: string) => {},
      getTenantId: async () => platform.getTenantId(),
      loadBodyProfiler: async () => Promise.resolve({
        getBodyFeatures: () => Promise.resolve(bodyProfile),
      }),
      bodyProfile: (forceNewUser?: boolean | undefined) => Promise.resolve(bodyProfile),
      loadDressingRoom: (
        parentElement: HTMLElement,
        options: IDressingRoomConfig,
      ) => Promise.resolve({} as IDressingRoomSDK),
      bodyProfileButton: (options: IButtonOptions) => {},
      sizeRecommendationButton: (options: ISizeRecommendationButtonOptions) => {},
      signOut: async () => platform.signOut(),
      signInAnonymously: async () => platform.signInAnonymously(),
      getBatchSizeRecommendation: async (
        variantIdentfiers: string[],
      ) => platform.userRecommendedSizesBatch(variantIdentfiers),
      getFitProbabilitiesForAllProducts: async () => platform.getFitProbabilitiesForAllProducts(),
      updateSizeRecommendationFeedback: async (
        recommendationId: string,
        feedbacks: IRecommendationFeedback[],
      ) => {
        platform.recommendationFeedback(recommendationId, feedbacks);
      },
    };
  },
};

const createPlatform = (): IPlatform => ({
  initialize: (options: IPlatformConfig) => Promise.resolve(),
  meeplButtonClicked: (userIntent: UserIntentState) => Promise.resolve(),
  getBodySnapshot: (mannequinUserId?: string) => Promise.resolve(null),
  getBodySnapshotMannequin: (mannequinUserId?: string) => Promise.resolve(null),
  recommendedSizes: (bodySnapshotId: string, variantId: string) => Promise.resolve({
    id: '',
    recommendations: [],
  }),
  recommendedSizesMannequin: (bodySnapshotId: string, variantId: string) => Promise.resolve({
    id: '',
    recommendations: [],
  }),
  requestSizeRecommendation: (variantIds: string[]) => Promise.resolve(),
  userRecommendedSizesBatch: (variantIds: string[]) => Promise.resolve({
    id: '',
    recommendations: [],
  }),
  getFitProbabilitiesForAllProducts: () => Promise.resolve([]),
  recommendationFeedback: () => Promise.resolve([]),
  signIn: (email: string, password: string) => Promise.resolve(),
  signInAnonymously: () => Promise.resolve(),
  signUp: (email: string, password: string) => Promise.resolve(),
  acceptLegalDocuments: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  deleteAccount: (password: string) => Promise.resolve(),

  getMannequinUserId: (gender: Gender, shape: BodyShape, bodyHeight: number) => Promise.resolve(null),

  productVariantGroups: () => Promise.resolve([]),
  variantGroups: (variantIds: string[]) => Promise.resolve([]),

  garmentSimulation: (
    bodyFeaturesSnapshotId: string,
    upperBodyGarmentId: string,
    lowerBodyGarmentId: string,
    userId?: string | null,
  ) => Promise.resolve({
    id: '',
    body: {
      meshUrl: '',
    },
    upperBodyGarment: {
      meshUrl: '',
      outsideTextureUrl: '',
      insideTextureUrl: '',
    },
    lowerBodyGarment: {
      meshUrl: '',
      outsideTextureUrl: '',
      insideTextureUrl: '',
    },
  }),

  garmentSimulationMannequin: (
    bodyFeaturesSnapshotId: string,
    upperBodyGarmentId: string,
    lowerBodyGarmentId: string,
    userId?: string | null,
  ) => Promise.resolve({
    id: '',
    body: {
      meshUrl: '',
    },
    upperBodyGarment: {
      meshUrl: '',
      outsideTextureUrl: '',
      insideTextureUrl: '',
    },
    lowerBodyGarment: {
      meshUrl: '',
      outsideTextureUrl: '',
      insideTextureUrl: '',
    },
  }),

  getTenantId: () => Promise.resolve(''),
});
