import * as React from 'react';
import styled from 'styled-components';

import { FilledSVG, OutlinedSVG, ZoomButton } from '../DressingRoomTheme';

const ZoomControls = (props: {
  onZoomOut: () => void,
  onZoomIn: () => void,
  onZoomReset: () => void,
}) => {
  const {
    onZoomReset,
    onZoomIn,
    onZoomOut,
  } = props;

  return (
    <ButtonContainer>
      <ZoomButton onClick={() => onZoomReset()}>
        <ZoomReset />
      </ZoomButton>
      <ZoomButton onClick={() => onZoomIn()}>
        <ZoomPlus />
      </ZoomButton>
      <ZoomButton onClick={() => onZoomOut()}>
        <ZoomMinus />
      </ZoomButton>
    </ButtonContainer>
  );
};

export default ZoomControls;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  height: 100%;
`;

const ZoomReset = () => (
  <FilledSVG
    width={16}
    height={16}
  >
    <g fillRule="evenodd" transform="translate(-192.000000, -192.000000)">
      <path d="M201,205.917042 C203.512502,205.49553 205.495527,203.512505 205.917042,201 L203,201 L203,199
      L205.917042,199 C205.495527,196.487495 203.512502,194.50447 201,194.082958 L201,197 L199,197 L199,194.082958
      C196.487498,194.50447 194.504473,196.487495 194.082958,199 L197,199 L197,201 L194.082958,201
      C194.504473,203.512505 196.487498,205.49553 199,205.917042 L199,203 L201,203 Z
      M200,208 C195.581722,208 192,204.418278 192,200 C192,195.581722 195.581722,192 200,192
      C204.418278,192 208,195.581722 208,200 C208,204.418278 204.418278,208 200,208 Z M200,208"
      />
    </g>
  </FilledSVG>
);

const ZoomSymbol = styled(OutlinedSVG)`
  stroke-width: 2px;
`;

const ZoomPlus = () => (
  <ZoomSymbol
    width={16}
    height={16}
  >
    <line x1={2} y1={8} x2={14} y2={8} />
    <line x1={8} y1={2} x2={8} y2={14} />
  </ZoomSymbol>
);

const ZoomMinus = () => (
  <ZoomSymbol
    width={16}
    height={16}
  >
    <line x1={2} y1={8} x2={14} y2={8} />
  </ZoomSymbol>
);
