// @ts-ignore
// eslint-disable-next-line max-classes-per-file
import TWEEN from '@tweenjs/tween.js';
import * as React from 'react';
import * as THREE from 'three';

import { isLinux } from '../../shared/browser_detection';

import linearRegression from './LinearRegression';
import { OrbitControls } from './OrbitControls';
import skyDome, { ISkyDomeConfig } from './SkyDome';
import { SnowAnimation } from './SnowAnimation';

export const lights = (): { group: THREE.Group, castShadow: (enable: boolean) => void } => {
  const group = new THREE.Group();

  const ambientLight = new THREE.AmbientLight(new THREE.Color('rgb(255, 255, 255)'), 0.4);
  group.add(ambientLight);

  const hemisphereLight = new THREE.HemisphereLight(
    new THREE.Color('rgb(128, 128, 128)'),
    new THREE.Color('rgb(32, 32, 32)'),
    0.8,
  );
  group.add(hemisphereLight);

  const directionalLight = new THREE.DirectionalLight(new THREE.Color('rgb(100, 100, 100)'), 1.1);
  directionalLight.position.set(2.5, 3.3, 1.3);
  directionalLight.target.position.set(0, 1, 0);
  directionalLight.target.updateMatrixWorld();
  directionalLight.castShadow = false;
  directionalLight.shadow.bias = -0.0015;
  directionalLight.shadow.radius = 16;
  directionalLight.shadow.mapSize.width = 1024;
  directionalLight.shadow.mapSize.height = 1024;
  directionalLight.shadow.camera.near = 1;
  directionalLight.shadow.camera.far = 7;
  directionalLight.shadow.camera.left = -1;
  directionalLight.shadow.camera.right = 1;
  directionalLight.shadow.camera.top = 1;
  directionalLight.shadow.camera.bottom = -1;
  group.add(directionalLight);

  return {
    group,
    castShadow: enable => { directionalLight.castShadow = enable; },
  };
};

const red = new THREE.Color('rgb(255, 0, 0)');
const green = new THREE.Color('rgb(0, 255, 0)');
const blue = new THREE.Color('rgb(0, 0, 255)');

export const coordSystem = (size: number): THREE.Object3D => {
  const group = new THREE.Group();
  const lineGeometry = new THREE.Geometry();
  lineGeometry.vertices.push(new THREE.Vector3());
  lineGeometry.vertices.push(new THREE.Vector3(0, size, 0));
  const coneGeometry = new THREE.CylinderGeometry(0, size / 20.0, size / 4.0, size / 20.0, 1);

  const xLine = new THREE.Line(lineGeometry, new THREE.LineBasicMaterial({ color: red }));
  xLine.rotation.z = -Math.PI / 2;
  group.add(xLine);
  const xCone = new THREE.Mesh(coneGeometry, new THREE.MeshBasicMaterial({ color: red }));
  xCone.position.x = size;
  xCone.rotation.z = -Math.PI / 2;
  group.add(xCone);

  const yLine = new THREE.Line(lineGeometry, new THREE.LineBasicMaterial({ color: green }));
  group.add(yLine);
  const yCone = new THREE.Mesh(coneGeometry, new THREE.MeshBasicMaterial({ color: green }));
  yCone.position.y = size;
  group.add(yCone);

  const zLine = new THREE.Line(lineGeometry, new THREE.LineBasicMaterial({ color: blue }));
  zLine.rotation.x = Math.PI / 2;
  group.add(zLine);
  const zCone = new THREE.Mesh(coneGeometry, new THREE.MeshBasicMaterial({ color: blue }));
  zCone.position.z = size;
  zCone.rotation.x = Math.PI / 2;
  group.add(zCone);

  return group;
};

const maxFramerateAnimationLoop = (
  onUpdate: (time: number, delta: number) => void,
): void => {
  const clock = new THREE.Clock();
  const state = {
    lastTime: clock.getElapsedTime(),
  };

  const animate = () => {
    window.requestAnimationFrame(animate);
    const currentTime = clock.getElapsedTime();
    const delta = currentTime - state.lastTime;
    state.lastTime = currentTime;
    onUpdate(currentTime, delta);
  };
  window.requestAnimationFrame(animate);
};

const onInputEventAnimationLoop = (
  onUpdate: (time: number, delta: number) => void,
  durationInSeconds: number,
): (
  ) => void => {
  const clock = new THREE.Clock();
  const state = {
    lastTime: clock.getElapsedTime(),
    lastEventTime: 0,
    isAnimating: false,
  };

  const animate = () => {
    const currentTime = clock.getElapsedTime();
    const delta = currentTime - state.lastTime;
    state.lastTime = currentTime;

    if (currentTime < state.lastEventTime + durationInSeconds) {
      window.requestAnimationFrame(animate);
    } else {
      state.isAnimating = false;
    }
    onUpdate(currentTime, delta);
  };

  const triggerAnimation = () => {
    state.lastEventTime = clock.getElapsedTime();
    if (!state.isAnimating) {
      state.lastTime = state.lastEventTime;
      state.isAnimating = true;
      window.requestAnimationFrame(animate);
    }
  };

  return triggerAnimation;
};

const measurePerformance = (
  onReport: (framesPerSecond: number) => void,
): (time: number
  ) => void => {
  let startTime = 0;
  let frameCount = 0;

  const fpsRingBuffer: number[] = [0, 0, 0];
  let ringIndex = 0;
  const addFps = (fpsValue: number) => {
    fpsRingBuffer[ringIndex] = fpsValue;
    ringIndex = (ringIndex + 1) % fpsRingBuffer.length;
  };
  const meanFps = () => fpsRingBuffer.reduce((sum, value) => sum + value, 0) / fpsRingBuffer.length;

  return (time: number) => {
    frameCount += 1;
    const diff = time - startTime;
    if (diff > 1.0) {
      const fps = frameCount / diff;
      const runningMean = meanFps();

      if (diff < 2.0 && fps > runningMean * 0.85 && fps < runningMean * 1.15) {
        onReport(fps);
      }

      addFps(fps);
      startTime = time;
      frameCount = 0;
    }
  };
};

const boundingCylinder = (object: THREE.Object3D | null) => {
  if (object == null) {
    return null;
  }
  const bounds = new THREE.Box3().setFromObject(object);
  const size = bounds.getSize(new THREE.Vector3());
  const inflation = 1.15; // cylinder approximated by linear segments, thus needs to be larger to encompass the object
  const radius = (Math.max(size.x, size.z) / 2) * inflation;
  const geometry = new THREE.CylinderGeometry(radius, radius, size.y);
  const cylinder = new THREE.Mesh(geometry);
  cylinder.position.copy(size.clone().multiplyScalar(0.5).add(bounds.min));
  cylinder.updateMatrixWorld(true);
  return cylinder;
};

class RotatingModel {
  private model: THREE.Object3D | null;

  private boundingVolume: THREE.Object3D | null;

  private scene: THREE.Scene;

  private rotation: { y: number };

  private rotationSpeed: number;

  constructor(scene: THREE.Scene, rotationSpeed = (Math.PI * 2.0) / 100.0) {
    this.scene = scene;
    this.rotation = { y: 0 };
    this.rotationSpeed = rotationSpeed;
    this.model = null;
    this.boundingVolume = null;

    this.setModel = this.setModel.bind(this);
    this.zoomReset = this.zoomReset.bind(this);
    this.update = this.update.bind(this);
  }

  public setModel(object3D: THREE.Object3D | null): void {
    if (this.model) {
      this.scene.remove(this.model);
      this.model.traverse(node => {
        if (node.type !== 'Mesh') { return; }
        const mesh = node as THREE.Mesh;
        const material = mesh.material as THREE.MeshStandardMaterial;

        if (material.map) {
          material.map.dispose();
        }
        material.dispose();
      });
    }
    this.model = object3D;
    if (this.model) {
      this.model.rotation.y = this.rotation.y;
      this.scene.add(this.model);
    }
    this.boundingVolume = boundingCylinder(this.model);
  }

  public getBoundingVolume() {
    return this.boundingVolume;
  }

  public update(delta: number): void {
    this.rotation.y = (this.rotation.y + delta * this.rotationSpeed) % (Math.PI * 2.0);

    if (this.model) {
      this.model.rotation.y = this.rotation.y;
    }
  }

  public zoomReset(time: number): void {
    new TWEEN.Tween(this.rotation)
      .to({
        y: this.rotation.y <= Math.PI ? 0 : Math.PI * 2.0,
      }, 1.0)
      .easing(TWEEN.Easing.Quintic.Out)
      .start(time);
  }
}

function ringBuffer<T>(capacity: number) {
  const buffer: T[] = [];
  let index = 0;

  return {
    add: (value: T) => {
      buffer[index] = value;
      index = (index + 1) % capacity;
    },
    buffer,
  };
}

/* eslint-disable no-console */
const logFree = <T, >(call: () => T): T => {
  const originalLog = console.log;
  console.log = () => undefined;
  const result = call();
  console.log = originalLog;
  return result;
};
/* eslint-enable no-console */

export enum RenderQuality {
  high,
  low,
  auto,
}

const lowResolutionPixelCount = 1280 * 720;

export default class ModelViewer {
  private orbitCamera: OrbitControls;

  private camera: THREE.PerspectiveCamera;

  private rotatingModel: RotatingModel;

  private snow: SnowAnimation | undefined;

  private doReset: boolean;

  private triggerUpdate: () => void;

  private initializePerformanceMonitor?: () => void;

  constructor(
    parentDomElement: Element,
    onUpdate: ((time: number, delta: number) => void) | null,
    quality: RenderQuality,
    alwaysAnimate: boolean,
    skyConfiguration: ISkyDomeConfig,
    onZoom?: (isZoomedIn: boolean) => void,
    createLights = lights,
    rotationSpeed?: number,
    cropLimits: [number, number] = [0.1, 2],
    backgroundColor: THREE.Color = new THREE.Color('rgb(230, 230, 230)'),
    fixAxisX = false,
    cropCenterNorm?: {x: number, y: number},
  ) {
    this.update = this.update.bind(this);
    this.setModel = this.setModel.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.zoomReset = this.zoomReset.bind(this);

    const useAntiAliasing = (quality === RenderQuality.high || (quality === RenderQuality.auto && !isLinux()))
      && window.devicePixelRatio <= 1;

    const renderer = logFree(() => new THREE.WebGLRenderer({ antialias: useAntiAliasing }));

    renderer.setSize(parentDomElement.clientWidth, parentDomElement.clientHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.VSMShadowMap;
    parentDomElement.appendChild(renderer.domElement);

    const cameraAspect = parentDomElement.clientWidth / parentDomElement.clientHeight;
    this.camera = new THREE.PerspectiveCamera(46, cameraAspect, 0.1, 1000);
    this.orbitCamera = OrbitControls(
      parentDomElement as HTMLElement,
      this.camera,
      new THREE.Vector3(2.0, 1.8, 2.15),
      new THREE.Vector3(0, 1.25, 0),
      () => {
        renderer.render(scene, this.camera);
      },
      cropLimits,
      fixAxisX,
      onZoom,
      () => this.rotatingModel.getBoundingVolume(),
      cropCenterNorm,
    );

    const scene = new THREE.Scene();
    scene.background = backgroundColor;

    this.rotatingModel = new RotatingModel(scene, rotationSpeed);

    scene.add(skyDome(skyConfiguration));
    const lighting = createLights();
    scene.add(lighting.group);

    let onResize: () => void = () => undefined;

    window.onresize = () => {
      this.camera.aspect = parentDomElement.clientWidth / parentDomElement.clientHeight;
      this.camera.updateProjectionMatrix();
      renderer.setSize(parentDomElement.clientWidth, parentDomElement.clientHeight);
      this.triggerUpdate();
      onResize();
    };

    ({
      [RenderQuality.high]: () => {
        renderer.setPixelRatio(window.devicePixelRatio);
        lighting.castShadow(true);
      },
      [RenderQuality.low]: () => {
        onResize = () => {
          const pixelCount = parentDomElement.clientWidth * parentDomElement.clientHeight;
          const ratio = Math.min(lowResolutionPixelCount / pixelCount, window.devicePixelRatio);
          renderer.setPixelRatio(ratio);
        };
        onResize();
      },
      [RenderQuality.auto]: () => undefined,
    })[quality]();

    let performanceMeasure: (time: number) => void = () => undefined;
    if (quality === RenderQuality.auto) {
      this.initializePerformanceMonitor = () => {
        const targetFramesPerSecond = 24;
        let ratio = 1.0;
        renderer.setPixelRatio(ratio);
        const samples = ringBuffer<{ ratio: number, framesPerSecond: number }>(5);

        lighting.castShadow(true);
        let shadowTest: any = () => {
          const pixelCount = parentDomElement.clientWidth * parentDomElement.clientHeight * ratio;
          if (ratio < 1.0 && pixelCount < lowResolutionPixelCount) {
            lighting.castShadow(false);
          }
        };

        performanceMeasure = measurePerformance(framesPerSecond => {
          samples.add({ ratio, framesPerSecond });

          let newRatio = (framesPerSecond * ratio) / targetFramesPerSecond;
          if (samples.buffer.length > 1) {
            const linearModel = linearRegression(samples.buffer
              .map(sample => ({ x: sample.framesPerSecond, y: sample.ratio })));

            if (linearModel.r2 > 0.5 && linearModel.slope < 0) {
              newRatio = linearModel.slope * targetFramesPerSecond + linearModel.intercept;
            }
          }

          if (Math.abs(ratio - newRatio) > 0.1) {
            const newRatioClipped = Math.max(0.33, Math.min(window.devicePixelRatio, newRatio));
            if (newRatioClipped !== ratio) {
              ratio = newRatioClipped;
              renderer.setPixelRatio(ratio);

              if (shadowTest) {
                shadowTest();
                shadowTest = undefined;
              }
            }
          }
        });
      };
    }

    this.doReset = false;

    const animate = (time: number, delta: number) => {
      performanceMeasure(time);

      this.update(time, delta);

      if (onUpdate) {
        onUpdate(time, delta);
      }

      renderer.render(scene, this.camera);
    };

    if (alwaysAnimate) {
      maxFramerateAnimationLoop(animate);
      this.triggerUpdate = () => undefined;
    } else {
      this.triggerUpdate = onInputEventAnimationLoop(animate, 10.0);
      this.triggerUpdate();
      renderer.domElement.addEventListener('mousemove', this.triggerUpdate);
      renderer.domElement.addEventListener('mousedown', this.triggerUpdate);
      renderer.domElement.addEventListener('touchstart', this.triggerUpdate, { passive: true });
      renderer.domElement.addEventListener('wheel', this.triggerUpdate, { passive: true });
    }

    let snowPromise: Promise<SnowAnimation>;
    const lazyLoadSnow = () => {
      if (snowPromise == null) {
        snowPromise = SnowAnimation(this.triggerUpdate)
          .then(snow => {
            this.snow = snow;
            scene.add(this.snow.getSceneObject());
            return snow;
          });
      }
      return snowPromise;
    };
    const addSomeSnow = () => lazyLoadSnow().then(snow => snow.add(5));

    renderer.domElement.tabIndex = 1;
    renderer.domElement.focus();
    window.addEventListener('keydown', event => {
      if (event.keyCode === 32) {
        addSomeSnow();
      }
    });

    renderer.domElement.addEventListener('touchstart', event => {
      if (event.touches.length === 3) {
        addSomeSnow();
      }
    }, { passive: true });
  }

  public setModel(object3D: THREE.Object3D | null) {
    this.rotatingModel.setModel(object3D);
    this.triggerUpdate();
    this.orbitCamera.updateView();

    if (this.initializePerformanceMonitor) {
      const initialize = this.initializePerformanceMonitor;
      this.initializePerformanceMonitor = undefined;
      initialize();
    }
  }

  public zoomIn(): void {
    this.orbitCamera.zoomIn();
    this.triggerUpdate();
  }

  public zoomOut(): void {
    this.orbitCamera.zoomOut();
    this.triggerUpdate();
  }

  public zoomReset(): void {
    this.doReset = true;
    this.triggerUpdate();
  }

  public getCamera() { return this.camera; }

  private update(time: number, delta: number) {
    TWEEN.update(time);
    this.rotatingModel.update(delta);
    this.orbitCamera.updateTime(time);
    if (this.snow) {
      this.snow.update(time, delta);
    }

    if (this.doReset) {
      this.doReset = false;
      this.orbitCamera.zoomReset();
      this.rotatingModel.zoomReset(time);
    }
  }
}

export const DomContainer = (props: { initialize: (parentDomElement: Element) => void }) => (
  <div
    ref={element => element && props.initialize(element)}
  />
);

export const viewModel = (
  parentDomElement: Element,
  model: THREE.Object3D,
  canvasWidth: number,
  canvasHeight: number,
): void => {
  const camera = new THREE.PerspectiveCamera(45, canvasWidth / canvasHeight, 0.25, 20);

  OrbitControls(
    parentDomElement as HTMLElement,
    camera,
    new THREE.Vector3(0, 1.2, 3),
    new THREE.Vector3(0, 0.8, 0),
  );

  const scene = new THREE.Scene();
  scene.background = new THREE.Color('rgb(230,230,230)');
  scene.add(coordSystem(0.5));

  const light = new THREE.HemisphereLight(
    new THREE.Color('rgb(220,220,220)'),
    new THREE.Color('rgb(68,68,34)'),
    1.2,
  );
  scene.add(light);

  if (model) {
    scene.add(model);
  }

  const renderer = new THREE.WebGLRenderer({ antialias: false });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(canvasWidth, canvasHeight);
  renderer.gammaOutput = true;

  parentDomElement.appendChild(renderer.domElement);
  renderer.render(scene, camera);

  const triggerUpdate = onInputEventAnimationLoop(() => {
    renderer.render(scene, camera);
  }, 3.0);
  renderer.domElement.addEventListener('mousemove', triggerUpdate);
  renderer.domElement.addEventListener('mousedown', triggerUpdate);
  renderer.domElement.addEventListener('touchstart', triggerUpdate, { passive: true });
};
