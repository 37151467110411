/* eslint-disable @typescript-eslint/prefer-regexp-exec */
type BrowserSpecifications = {
  name: string,
  version: number,
};

export const isAndroidOrIOS = () => ['Android', 'iOS'].includes(getMobileOS());

export const getMobileOS = () => {
  // from https://stackoverflow.com/a/21742107/214446
  const win = window as any;
  const userAgent = navigator.userAgent || navigator.vendor || win.opera;
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !win.MSStream) {
    return 'iOS';
  }
  return 'unknown';
};

// Edited version from https://stackoverflow.com/a/23522755/214446
// Firefox and Chrome on iOS are wrappers around Safari and use therefore the same redirect logic as Safari itself.
export const isSafariEngine = () => navigator.vendor && navigator.vendor.includes('Apple');

export const isEdge = () => /edge/i.test(navigator.userAgent);

export const isLinux = () => /linux/i.test(navigator.userAgent)
  && !/android/i.test(navigator.userAgent);

export const isPocophone = () => /pocophone/i.test(navigator.userAgent);

// Edited version from https://stackoverflow.com/a/16938481/1712944
// The version number is only the major one. The number is converted from string to number.
export const getBrowserSpecifications = (): BrowserSpecifications => {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: parseInt(tem[1] || '', 10) };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) { return { name: 'Opera', version: parseInt(tem[1], 10) }; }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  tem = ua.match(/version\/(\d+)/i);
  if (tem != null) { M.splice(1, 1, tem[1]); }
  return {
    name: M[0],
    version: parseInt(M[1], 10),
  };
};
