import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const logit = (x: number) => Math.log10(x / (1 - x * x * x));

const linearScale = (x: number, inputMin: number, inputMax: number, outputMin: number, outputMax: number): number => {
  const inputNorm = (x - inputMin) / (inputMax - inputMin);
  return outputMin + inputNorm * (outputMax - outputMin);
};

const angleToCoords = (angleInDegrees: number) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return { x: Math.cos(angleInRadians), y: Math.sin(angleInRadians) };
};

const arcPath = (radius: number, endAngle: number): string => {
  const start = angleToCoords(0);
  const end = angleToCoords(endAngle);
  const arcSweep = endAngle <= 180 ? '0' : '1';

  return [
    'M', end.x * radius, end.y * radius,
    'A', radius, radius, 0, arcSweep, 0, start.x * radius, start.y * radius,
  ].join(' ');
};

const spriteSize = 128;

const keys = Array(100).fill(null).map((value, index, array) => {
  const normInput = index / (array.length - 1);
  const logitOutput = Math.max(-2, Math.min(2, logit(normInput)));
  const percent = linearScale(logitOutput, -2, 2, 0, 100);
  return `${percent}% { transform: translateX(-${index * spriteSize}px); }`;
}).join('\n');

const sprites = () => Array(101).fill(null).map((value, index) => {
  const percent = index;
  const arc = arcPath(60, Math.min(359.999, percent * 3.6));
  const key = `frame${index}`;
  return (
    <g key={key} transform={`translate(${spriteSize * index} 0)`}>
      <CircleFill r={60} />
      <CirclePath d={arc} />
      <PercentageText dy="0.35em">
        {`${percent}%`}
      </PercentageText>
    </g>
  );
});

type IProps = {
  isLoading: boolean,
  failed: boolean,
  durationInSeconds: number,
  onCancel?: (() => void) | null,
};

enum AnimationState {
  Inactive = 'Inactive',
  Animating = 'Animating',
  Finishing = 'Finishing',
}

type IState = {
  startTime: number,
  animationState: AnimationState,
};

const shortLoadingTimeInSeconds = 2;

export default class LoaderDisplay extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      startTime: Date.now(),
      animationState: props.isLoading ? AnimationState.Animating : AnimationState.Inactive,
    };
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      isLoading,
    } = this.props;

    if (isLoading && !prevProps.isLoading) {
      this.start();
    } else if (!isLoading && prevProps.isLoading) {
      this.end();
    }
  }

  public render() {
    const {
      failed,
      durationInSeconds,
      onCancel,
    } = this.props;
    const {
      animationState,
    } = this.state;

    if (animationState === AnimationState.Inactive || failed) {
      return null;
    }

    const frames = sprites();

    return (
      <>
        <ShortLoadingOverlay>
          <Disappear after={shortLoadingTimeInSeconds}>
            <SpinningWaitingAnimation />
          </Disappear>
        </ShortLoadingOverlay>
        <LongLoadingOverlay>
          <Appear after={shortLoadingTimeInSeconds}>
            <svg
              width={spriteSize}
              height={spriteSize}
              viewBox={`${-spriteSize / 2} ${-spriteSize / 2} ${spriteSize} ${spriteSize}`}
            >
              { animationState === AnimationState.Finishing
                ? <g transform={`translate(-${100 * spriteSize} 0)`}>{frames}</g>
                : <SpriteContainer durationInSeconds={durationInSeconds}>{frames}</SpriteContainer>}
              { onCancel && (
              <CancelButton
                transform="translate(56, -56)"
                onClick={() => {
                  this.setState({
                    animationState: AnimationState.Inactive,
                  });
                  onCancel();
                }}
              >
                <CancelCircle r={8} />
                <CancelLine x1={-4} y1={4} x2={4} y2={-4} />
                <CancelLine x1={-4} y1={-4} x2={4} y2={4} />
              </CancelButton>
              )}
            </svg>
          </Appear>
        </LongLoadingOverlay>
      </>
    );
  }

  private start() {
    this.setState({
      startTime: Date.now(),
      animationState: AnimationState.Animating,
    });
  }

  private end() {
    const {
      startTime,
      animationState,
    } = this.state;

    if (animationState !== AnimationState.Animating) {
      return;
    }

    const deactivate = () => {
      this.setState({
        animationState: AnimationState.Inactive,
      });
    };

    if (Date.now() - startTime < shortLoadingTimeInSeconds * 1000) {
      deactivate();
    } else {
      this.setState({
        animationState: AnimationState.Finishing,
      });
      setTimeout(deactivate, 900);
    }
  }
}

const LongLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
`;

const ShortLoadingOverlay = styled(LongLoadingOverlay)`
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

const PercentageText = styled.text`
  text-anchor: middle;
  font-size: 40px;
`;

const CircleFill = styled.circle`
  fill: rgb(238, 238, 238);
  opacity: 0.8;
  stroke: none;
`;

const CirclePath = styled.path`
  fill: none;
  stroke: rgb(60, 64, 76);
  stroke-width: 4;
`;

const spriteKeys = keyframes`
  ${keys}
`;

const SpriteContainer = styled.g<{ durationInSeconds: number }>`
  animation-name: ${spriteKeys};
  animation-duration: ${props => `${props.durationInSeconds}s`};
  animation-timing-function: step-start;
  animation-fill-mode: forwards;
`;

const disappearKeys = keyframes`
  0% {
    visibility: visible;
    opacity: 1;
  }
  80% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
`;

export const Disappear = styled.div<{ after: number }>`
  animation-name: ${disappearKeys};
  animation-duration: ${props => `${props.after}s`};
  animation-fill-mode: both;
`;

const appearKeys = keyframes`
  0% {
    visibility: hidden;
    opacity: 0;
  }
  80% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
`;

const Appear = styled.div<{ after: number }>`
  animation-name: ${appearKeys};
  animation-duration: ${props => `${props.after}s`};
`;

const SpinningWaitingAnimation = () => (
  <Spinner>
    <Bouncer delay={-0.32} />
    <Bouncer delay={-0.16} />
    <Bouncer delay={0} />
  </Spinner>
);

const Spinner = styled.div`
  text-align: center;
  width: 66px;
`;

const bounceKeys = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
`;

const Bouncer = styled.div<{ delay: number }>`
  width: 12px;
  height: 12px;
  margin: 4px 4px;
  background-color: rgb(238, 238, 238);

  border-radius: 100%;
  display: inline-block;
  animation: ${bounceKeys} 1.4s infinite ease-in-out both;
  animation-delay: ${props => `${props.delay}s`};
`;

const CancelButton = styled.g`
  cursor: pointer;
`;

const CancelCircle = styled.circle`
  fill: rgb(238, 238, 238);
  opacity: 0.8;
  stroke: none;
`;

const CancelLine = styled.line`
  stroke: rgb(60, 64, 76);
  stroke-width: 2;
`;
